import { HttpClient } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CategoriesService } from '../../../services/categories.service';
import { AppConfigService } from '../../../../../services/app-config/app-config.service';
import { BaseResultModel } from '../../../../../models/baseResultModel';
import { AssetModel } from '../../../models';
import { SearchUserAssetsModel } from '../../../models';
import { SearchResponseModel } from '../../../../../models/search/searchResponseModel';

const DEFAULTFILTER = {
  displayAll: false,
  pageNo: 1,
  pageSize: 16,
  sortColumns: [],
  searchParameters: {}
};

@Injectable({
  providedIn: 'root'
})
export class SearchAssetsService {
  private _uri = '/api/assets';
  private _appConfigService;
  constructor(
    private httpClient: HttpClient,
    private categoriesService: CategoriesService,
    @Optional() appConfigService: AppConfigService
  ) {
    this._appConfigService = appConfigService;
    if (this._appConfigService) {
      this._uri = this._appConfigService.getResourceModuleApiUrl() + this._uri;
    }
  }

  find(params): Observable<SearchResponseModel> {
    const finalRequest: any = this.removeEmptyKeys(params);
    return this.httpClient.post(`${this._uri}/search`, finalRequest);
  }

  getFavoritesAssets(filters?) {
    return this.httpClient
      .get(`${this._uri}/favorites`, {
        params: filters
      })
      .pipe(
        map((response: BaseResultModel<AssetModel[]>) => response.returnValue)
      );
  }

  getTrendingAssets(filters?) {
    const requestObj = JSON.parse(JSON.stringify(filters || DEFAULTFILTER));
    return this.httpClient
      .post(`${this._uri}/trending`, requestObj)
      .pipe(map((response: SearchResponseModel) => {
        if (response && response.result && response.result.length) {
          return response.result[0].items;
        }
        return [];
      }));
  }

  getFeaturedAssets(filters?) {
    const requestObj = JSON.parse(JSON.stringify(filters || DEFAULTFILTER));
    return this.httpClient
      .post(`${this._uri}/featured`, requestObj)
      .pipe(map((response: SearchResponseModel) => {
        if (response && response.result && response.result.length) {
          return response.result[0].items;
        }
        return [];
      }));
  }

  getAssetsByCategory(categories: number[], filters?) {
    const requestObj = JSON.parse(JSON.stringify(filters || DEFAULTFILTER));
    requestObj.searchParameters.CategoryIds = categories;
    return this.httpClient
      .post(`${this._uri}/search`, requestObj)
      .pipe(map((response: SearchResponseModel) => {
        if (response && response.result && response.result.length) {
          return response.result[0].items;
        }
        return [];
      }));
  }

  searchUserAssets(filters?): Observable<BaseResultModel<SearchUserAssetsModel>> {
    const requestObj = JSON.parse(JSON.stringify(filters || DEFAULTFILTER));

    requestObj.sortColumns = [{ "sort": "DESC", "colId": "Resource.CreatedDate", "fieldType": "date" }];

    return this.httpClient
      .post<BaseResultModel<SearchUserAssetsModel>>(`${this._uri}/searchUserAssets`, requestObj);
  }

  private removeEmptyKeys(obj) {
    const tempObj = {};
    const keys = Object.keys(obj);
    keys.forEach(key => {
      if ((typeof obj[key] !== 'object' || (Array.isArray(obj[key]))) && (obj[key] || obj[key] === false)) {
        tempObj[key] = obj[key];
      }
      if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
        tempObj[key] = this.removeEmptyKeys(obj[key]);
      }
    });
    return tempObj;
  }
}
