import { Component, OnInit, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material';
import { DynamicDialogRef } from 'primeng/api';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.css']
})
export class ConfirmationModalComponent implements OnInit {

  @HostListener('document:keyup.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    // console.log("closing");
    this.ref.close();
  }
  constructor(public ref: DynamicDialogRef) { }

  ngOnInit() {
  }

  public closeDialog() {
    this.ref.close();
  }

}
