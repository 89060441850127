import { Pipe, PipeTransform } from '@angular/core'
import { TenantService } from 'src/app/services/tenant/tenant.service';
import { map } from 'rxjs/operators';
import { UserService } from '../services/user/user.service';
import { of } from 'rxjs';


@Pipe({ name: 'categoryName' })
export class CategoryNamePipe implements PipeTransform {
  constructor(private tenantService: TenantService, private userService: UserService) {
  }
  transform(categoryId: number, args: string): any {
    
    if (categoryId != null && categoryId > 0) {


      this.userService.events().subscribe(message => {
        if (message.event == UserService.CURRENT_USER_FETCHED_EVENT) {
          var locale = this.userService.currentUser().browsingLanguagePreference;
          return this.getCateogryName(categoryId, locale);
        }
        });

      if (this.userService.currentUser() != null) {
       
        return this.getCateogryName(categoryId, this.userService.currentUser().browsingLanguagePreference);
       
      }


      return of("");     
    }
  }

  getCateogryName(categoryId: number, locale: string): any {
    var categoryName = "";
    return this.tenantService.getCategories().pipe(map(y => {
      var currentLocale = locale;
      
      if (y != null) {
        var catName = y.filter(cat => cat.categoryId == categoryId);
        if (catName.length == 0) {
          y.forEach(x => {

            var subcats = x.subCategories.filter(a => a.categoryId == categoryId);
            //var subcats = catName[0].subCategories.filter(cat1 => cat1.categoryId == categoryId);
            if (subcats != null && subcats.length > 0) {
              var localeSubCatName = subcats[0].categoryLocale.filter(a => a.locale == currentLocale);
              if (localeSubCatName != null && localeSubCatName.length > 0) {
                categoryName = localeSubCatName[0].categoryName;
              }
            }
          });
          //var subcats = catName.filter(cat => cat.subCategories != null && cat.subCategories.filter(cat1 => cat1.categoryId == categoryId).length > 0);
          ////var subcats = y.filter(cat => cat.subCategories != null && cat.subCategories.filter(cat1 => cat1.categoryId == categoryId).length > 0);
          //console.log(subcats);
          //if (subcats != null && subcats.length > 0) {
          //  catName = subcats[0].subCategories.filter(cat => cat.categoryId == categoryId);
          //  console.log(catName);
          //}
        }

        if (catName != null && catName.length > 0) {
          if (currentLocale != null && currentLocale != "") {
            var localeCatName = catName[0].categoryLocale.filter(a => a.locale == currentLocale);
            if (localeCatName !=null && localeCatName.length > 0) {
              categoryName = localeCatName[0].categoryName;
            }
          }


          if (categoryName == "") {
            categoryName = catName[0].categoryName;
          }
        }
      }
      return categoryName;
    }));

    
  }


}

