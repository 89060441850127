<ng-template #template *ngIf="!fancy">  
    <div class="modal-header">
      <div class="modal-title pull-left">
        <ng-content select="[header]"></ng-content>
      </div>
      <button *ngIf="showCloseIcon" type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <ng-content select="[body]"></ng-content>

      <ng-container app-component-container></ng-container>
    </div>
    <div class="modal-footer">
      <ng-content select="[footer]"></ng-content>
    </div>  
</ng-template>


<swal  #swalComponent  *ngIf="fancy">
  <div *swalPartial="swalTargets.title">
    <ng-content select="[headerFancy]"></ng-content>
  </div>

 
  <div *swalPartial>
    <ng-content select="[bodyFancy]"></ng-content>
  </div>

 
  <ng-container *swalPartial="swalTargets.actions">
    <ng-content select="[footerFancy]"></ng-content>
  </ng-container>
</swal>
