import { Injectable, Optional } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
/**
 * Service class.
 */
export class SynapzeBlockUIService {
  private subject = new Subject<any>();

  start(instanceName: string, message?: string) {
    this.subject.next({ instance: instanceName, message: message, event: "start" });
  }
  stop(instanceName: string) {
    this.subject.next({ instance: instanceName, event: "stop" });
  }

  get(): Observable<any> {
    return this.subject.asObservable();
  }
}
