import { InjectionToken, Inject, Injectable } from '@angular/core';
import { StorageService } from 'ngx-webstorage-service';
import { OAuthStorage } from 'angular-oauth2-oidc';

export const CORE_LOCAL_STORAGE = new InjectionToken<StorageService>('CORE_LOCAL_STORAGE');
export const CORE_SESSION_STORAGE = new InjectionToken<StorageService>('CORE_SESSION_STORAGE');

@Injectable()
export class OAuthStorageService implements OAuthStorage {
  constructor(@Inject(CORE_SESSION_STORAGE) private sessionStorage: StorageService) {
  }

  getItem(key: string): string {
    return this.sessionStorage.get(key);
  }

  removeItem(key: string): void {
    return this.sessionStorage.remove(key);
  }

  setItem(key: string, data: string): void {
    return this.sessionStorage.set(key, data);
  }
}

