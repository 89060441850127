import { OnInit, Component, Input, Output, EventEmitter, forwardRef } from "@angular/core";
import { DropDownItemModel } from "src/app/models/DropDownItemModel";
import { FormGroup, FormControl, FormBuilder, ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: 'synapze-cx-simple-dropdown',
  templateUrl: 'synapze-cx-simple-dropdown.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SynapzeCxSimpleDropDownComponent),
    multi: true,
  }]
})
export class SynapzeCxSimpleDropDownComponent implements ControlValueAccessor, OnInit {

  dropDownForm: FormGroup;

  private _selectedItems: any;
  _placeholder: string = "";
  _dropdownitems: DropDownItemModel[] = [];
  _title: string = "";
  _label: string = "";
  _readOnly: boolean = false;
  _onlyShowTotalNoOfItemsSelected = true;
  _currentSelectedCount = 0;

  @Input() showLabel: boolean = false;

  @Input() enableMultiSelection: boolean = true;

  _closeOnSelect: boolean = false;

  @Input()
  public set onlyShowTotalNoOfItemsSelected(val: boolean) { this._onlyShowTotalNoOfItemsSelected = val }
  public get onlyShowTotalNoOfItemsSelected(): boolean { return this._onlyShowTotalNoOfItemsSelected; }

  @Input()
  public set currentSelectedCount(val: number) { this._currentSelectedCount = val }
  public get currentSelectedCount(): number { return this._currentSelectedCount; }

  @Input()
  public set closeOnSelect(closeonSelection: boolean) { this._closeOnSelect = closeonSelection }
  public get closeOnSelect(): boolean { return this._closeOnSelect; }

  @Input()
  public set readOnly(value: boolean) {
    this._readOnly = value;
    if (this.dropDownForm != null) {
      if (this._readOnly)
        this.dropDownForm.disable();
      else
        this.dropDownForm.enable();
    }



  }
  public get readOnly(): boolean {
    return this._readOnly;
  }

  @Input()
  public get dropdownitems(): DropDownItemModel[] {
    return this._dropdownitems;
  }
  public set dropdownitems(value: DropDownItemModel[]) {
    this._dropdownitems = value;
    if (this._dropdownitems != null && this.dropdownitems.length > 0 && this.SelectedItems != null)
      this.selectDropDown(this.SelectedItems);
  }

  @Input()
  public set placeholder(value: string) {
    this._placeholder = value;
  }
  public get placeholder(): string {
    return this._placeholder;
  }

  @Input()
  public get title(): string {
    return this._title;
  }
  public set title(value: string) {
    this._title = value;
  }

  @Input()
  public get label(): string {
    return this._label;
  }
  public set label(value: string) {
    this._label = value;
  }


  @Input()
  public get SelectedItems(): any {

    if (this.dropDownForm != null)
     return this.dropDownForm.controls['dropdownControl'].value
    return this._selectedItems;
  }
  public set SelectedItems(value: any) {
    this._selectedItems = value;
    this.selectDropDown(value);

  }

  @Output() onSelectionChanged = new EventEmitter<DropDownItemModel[]>();

  onFCChange;

  constructor() {
  }

  ngOnInit(): void {
    this.dropDownForm = new FormGroup({
      dropdownControl: new FormControl()
    });

    if (this._readOnly)
      this.dropDownForm.disable();
    else
      this.dropDownForm.enable();

  }

  selectDropDown(value: any) {
    if (this.dropDownForm != null) {
      if (value == null || value.length == 0) {
        this.dropDownForm.reset();
        return;
      }
      else {
        this.dropDownForm.controls['dropdownControl'].setValue(value);
      }
    }
  }

  onChange() {
    this.onSelectionChanged.emit(this.dropDownForm.controls['dropdownControl'].value)
    if (this.onFCChange != null)
      this.onFCChange(this.dropDownForm.controls['dropdownControl'].value);
  }

  writeValue(obj: any): void {

    this.SelectedItems = obj;
  }
  registerOnChange(fn: any): void {
    this.onFCChange = fn;
  }
  registerOnTouched(fn: any): void {
    //this.onChange = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.readOnly = isDisabled;
  }

}
