import { Injectable, isDevMode } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateDefaultParser, TranslateLoader } from '@ngx-translate/core';
import { AppConfigService } from 'src/app/services/app-config/app-config.service';
import { AppConfig } from '../vos/app-config/app-config';


export class LocalizationLoader implements TranslateLoader {

  private config: AppConfig;
  private httpParams: HttpParams;

  constructor(private http: HttpClient, classKey: string, application: string, appConfigService: AppConfigService) {
    this.config = appConfigService.get();
    this.httpParams = new HttpParams()
      .set('classKey', classKey)
      .set('application', application);
  }

  getTranslation(lang: string): Observable<any> {
    const params = new HttpParams({ fromString: this.httpParams.toString() }).set('locale', lang);
    let uri = '';
    if (this.config != null) {
      uri = this.config.tenantConfig.coreService.serviceUrl;
      return this.http.get(`${uri}/api/resourcestring`, { params: params });
    }
    return of(undefined);
  }
}

export class MyMissingTranslationHandler implements MissingTranslationHandler {

  constructor(private appConfigService: AppConfigService, private http: HttpClient) { }

  handle(params: MissingTranslationHandlerParams) {
    let defaultText = '';

    if (params.interpolateParams != null) {
      defaultText = (<any>params.interpolateParams).defaultText || '';
    }

    // insert the missing key into the database
    if (isDevMode() && defaultText != null && defaultText !== '') {
      const url = `${this.appConfigService.get().tenantConfig.coreService.serviceUrl}/api/resourcestring/insert`;
      this.http.post<any>(url, JSON.stringify(`${params.key}:${defaultText}`))
        .subscribe(() => { });
    }

    return params.translateService.parser.interpolate(defaultText || params.key, params.interpolateParams);
  }
}

@Injectable()
export class CustomTranslateParser extends TranslateDefaultParser {
  /**
   * Regex to replace params
   * This is a { key }" ==> "This is a value", with params = { key: "value" }
   */
  public templateMatcher: RegExp = /{{?\s?([^{}\s]*)\s?}?}/g;
}
