
import { Component, Inject } from '@angular/core';
import { CORE_SESSION_STORAGE } from 'src/app/services/storage/storage.service';
import { StorageService } from 'ngx-webstorage-service';

@Component({
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css'],
})
/**
 * Logout page.
 */
export class LogoutComponent {
  constructor(@Inject(CORE_SESSION_STORAGE) private sessionStorage: StorageService) { 
    this.sessionStorage.clear();
  }
  
  login(){
    window.location.href = this.getOrigin();
  }

  getOrigin(): string {

    if (!window.location.origin) {
      return window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
    }
    else {
      return window.location.origin;
    }
  }

}
