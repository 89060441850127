import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Optional, Inject } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';
import { flatMap, map, tap } from 'rxjs/operators';

import { ResourceCategoryModel } from '../models';
import { AppConfigService } from '../../../services/app-config/app-config.service';
import { Constants } from '../../../shared/shared.constants';
import { BaseResultModel } from '../../../models/baseResultModel';
import { CORE_SESSION_STORAGE } from 'src/app/services/storage/storage.service';
import { StorageService } from 'ngx-webstorage-service';

const CACHE_SIZE = 1;


@Injectable({
  providedIn: 'root'
})
export class CategoriesService {


  private _uri = '/api/resources';

  private _appConfigService: AppConfigService;

  private _configuration: any;

  private resourceModuleUrl = '';

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private http: HttpClient,
    @Optional() appConfigService: AppConfigService,
    @Inject(CORE_SESSION_STORAGE) private sessionStorage: StorageService) {
    this._appConfigService = appConfigService;
    if (this._appConfigService) {
      const resourceModule = this._appConfigService.getModule(this._appConfigService.get().tenantConfig.resourceModuleCode);
      this.resourceModuleUrl = (resourceModule != null) ? resourceModule.serviceUrl : "";

      this._uri = `${this.resourceModuleUrl}` + this._uri;
    }
  }


  getUserCategories(): Observable<ResourceCategoryModel[]> {
    const userCategories = this.sessionStorage.get(Constants.USER_CATEGORIES);
    if (userCategories !== undefined && userCategories != null && userCategories != '' && userCategories != 'null' ) {
      return of(<any[]>JSON.parse(userCategories));
    } else {
      const endpoint = `${this.resourceModuleUrl}/api/user/categories`;
      return this.http.get<BaseResultModel<ResourceCategoryModel[]>>(`${endpoint}`).pipe(
        map(response => response.returnValue),
        tap((categories) => this.sessionStorage.set(Constants.USER_CATEGORIES, JSON.stringify(categories)))
      );
    }
  }

  setUserCategories(categoriesIDs): Observable<boolean> {
    const endpoint = `${this.resourceModuleUrl}/api/user/categories`;
    return this.http.post<BaseResultModel<any>>(`${endpoint}`, categoriesIDs).pipe(
      tap(() => sessionStorage.removeItem(Constants.USER_CATEGORIES)),
      map(response => !!response.success)
    );
  }

  getCategoriesConfiguration(): Observable<any> {
    const categoriesConfiguration = this.sessionStorage.get(Constants.TENANT_CATEGORIES_CONFIGURATION);
    if (categoriesConfiguration !== undefined && categoriesConfiguration !== null && categoriesConfiguration !== '') {
      return of(<any[]>JSON.parse(categoriesConfiguration));
    } else {
      const endpoint = `${this.resourceModuleUrl}/api/categories/CategoryConfiguration`;
      return this.http.get<any>(`${endpoint}`).pipe(
        tap((config) => this.sessionStorage.set(Constants.TENANT_CATEGORIES_CONFIGURATION, JSON.stringify(config)))
      );
    }
  }

}
