export class dataGridModel<T> {
  constructor() {
    this.sortColumns = [];
    this.pageSize = 15;
    this.pageNo = 1;
    this.displayAll = false;
  }
  pageNo?: number;
  pageSize?: number;
  searchParameters: T;
  sortColumns?: sortModel[];
  displayAll: boolean;
}


export class sortModel {
  colId?: string;
  sort?: string;
  fieldType?: string;
}

