import { FormArray } from '@angular/forms';
import { Category } from 'src/app/models/category';

export function CategoriesValidator(config: { min: number, max: number }) {
  return function (categoriesArray: FormArray): { [key: string]: any } | null {
    const selectedCategories: Category[] = categoriesArray.value.filter(cat => cat.selected);
    if (config.min > selectedCategories.length) {
      return { min: true };
    }
    if (config.max < selectedCategories.length) {
      return { max: true };
    }
    return null;
  };
}
