import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { BaseResultModel } from 'src/app/models/baseResultModel';
import { MediaBlobModel, MediaModel } from 'src/app/models/media/mediaModel';
import { AssetModel } from 'src/app/modules/resourceLibrary/models';
import { AppConfigService } from 'src/app/services/app-config/app-config.service';

@Injectable()
/**
 * Service class.
 */
export class MediaService {

  /**
   * Path uri.
   * @type {string}
   * @private
   */
  private _uri = '/api/media';

  /**
   * Url to endpoint api.
   * @type {string}
   */
  private endpoint = '';

  private _appConfigService: AppConfigService;
  private _sanitizer: DomSanitizer;

  /**
   * Endpoint request headers.
   * @type {HttpHeaders}
   */
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  /**
   * Component constructor and DI injection point.
   * @param {HttpClient} http
   */
  constructor(private http: HttpClient, @Optional() appConfigService: AppConfigService, sanitizer: DomSanitizer) {
    this._appConfigService = appConfigService;
    this._sanitizer = sanitizer;
    if (this._appConfigService) {
      this._uri = `${this._appConfigService.get().tenantConfig.coreService.serviceUrl}` + this._uri;
      // console.log(this._uri);
    }
  }

  /**
   * Pulls a list of modules
   * @returns {Observable<MediaModel>}
   */
  get(id: string): Observable<MediaModel> {
    var endpoint = `/${id}`;
    return this.http.get<MediaModel>(`${this._uri}${endpoint}`);
  }

  /**
   * Removes a media
   * @returns {Observable<MediaModel>}
   */
  delete(id: string): Observable<boolean> {
    var endpoint = `/${id}`;
    return this.http.delete<boolean>(`${this._uri}${endpoint}`);
  }


  ///**
  // * Pulls a list of modules
  // * @returns {Observable<Blob>}
  // */
  //getImageData(id: string): Observable<Blob> {
  //  var endpoint = `/image/${id}`;
  //  return this.http.get(`${this._uri}${endpoint}`, { responseType: 'blob', observe: 'response' })
  //    .pipe(map(res => res.body));


  //  //return this.http.get<Blob>(`${this._uri}${endpoint}`, { responseType: 'blob'});
  //}


  /**
   * Pulls a thumbnail and creates a sanitized blob
   * @returns {Observable<any>}
   */
  getThumbnailBlob(asset: AssetModel): Observable<any> {
    return Observable.create(observer => {
      this.getImageData(asset.thumbnailId).subscribe(media => {
        if (media && media.data) {
          const binary = atob(media.data.replace(/\s/g, ''));
          const byteNumbers = new Array(binary.length);
          for (let i = 0; i < binary.length; i++) {
            byteNumbers[i] = binary.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = window.URL.createObjectURL(new Blob([byteArray], { type: media.contentType }));
          observer.next(this._sanitizer.bypassSecurityTrustUrl(blob));
          observer.complete();
        }
      });
    });
  }


  /**
   * Pulls a thumbnail and creates a sanitized blob
   * @returns {Observable<any>}
   */
  setThumbnailBlob(asset: AssetModel): Observable<any> {
    return Observable.create(observer => {
      if (asset.thumbnailId) {
        this.getImageData(asset.thumbnailId).subscribe(media => {
          if (media && media.data) {
            const binary = atob(media.data.replace(/\s/g, ''));
            const byteNumbers = new Array(binary.length);
            for (let i = 0; i < binary.length; i++) {
              byteNumbers[i] = binary.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = window.URL.createObjectURL(new Blob([byteArray], { type: media.contentType }));
            asset.thumbnailBlob = this._sanitizer.bypassSecurityTrustUrl(blob);
            observer.next();
            observer.complete();
          }
        });
      } else {
        //switch (asset.assetTypeCode) {
        //  case 'VIDEO_MP4':
        //    asset.thumbnailBlob = '../../../../assets/css/assets/video_mp4.png';
        //    break;
        //  case 'URL_PODCAST':
        //    asset.thumbnailBlob = '../../../../assets/css/assets/podcast.png';
        //    break;
        //  case 'PDF':
        //    asset.thumbnailBlob = '../../../../assets/css/assets/pdf.png';
        //    break;
        //  case 'URL_WEBINAR':
        //    asset.thumbnailBlob = '../../../../assets/css/assets/url_webinar.png';
        //    break;
        //}
        observer.next();
        observer.complete();
      }
    });
  }

  /**
   * Pulls a list of modules
   * @returns {Observable<any>}
   */
  getImageData(id: string, width?: number, height?: number): Observable<any> {
    let endpoint = `/image/${id}`;
    if (width) {
      endpoint += `/w/${width}`
    }
    if (height) {
      endpoint += `/h/${height}`
    }
    return this.http.get(`${this._uri}${endpoint}`);
    // return this.http.get<Blob>(`${this._uri}${endpoint}`, { responseType: 'blob'});
  }

  getMultiple(ids: string[]): Observable<MediaModel[]> {
    const endpoint = `/GetMultiple`;
    return this.http.post<MediaModel[]>(`${this._uri}${endpoint}`, ids);
  }

  getMedia(id: string): Observable<MediaBlobModel> {
    const endpoint = `/file/${id}`;
    return this.http.get<MediaBlobModel>(`${this._uri}${endpoint}`);
  }

  /**
 * Updates the media.
 * @returns {Observable<BaseResultModel<MediaModel>>}
 */
  update(model: MediaModel): Observable<BaseResultModel<MediaModel>> {
    const endpoint = '';
    if (model.mediaId != null && model.mediaId !== '') {
      return this.http.put<BaseResultModel<MediaModel>>(`${this._uri}${endpoint}/${model.mediaId}`, model);
    } else {
      return this.http.post<BaseResultModel<MediaModel>>(`${this._uri}${endpoint}`, model);
    }
  }

}
