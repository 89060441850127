<div class="clearfix">
  <app-synapze-app-menu (searchToggled)="displaySearchResults($event)"></app-synapze-app-menu>

</div>

<nav class="navbar navbar-default" *ngIf="false">
  <div class="container-fluid">
    <!-- Brand and toggle get grouped for better mobile display -->
    <div class="navbar-header">
      <button type="button" class="navbar-toggle collapsed" (click)="isCollapsed = !isCollapsed" aria-expanded="false">
        <span class="sr-only">Toggle navigation</span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
      <a class="navbar-brand" href="#">SynapzeCX</a>
    </div>

    <!-- Collect the nav links, forms, and other content for toggling -->
    <ul class="nav navbar-nav">
      <ng-container *ngFor="let item of navigationLinks">
        <li *ngIf="hasAccess(item.uiModuleCode)"><a style="color:white" [routerLink]="[item.route]">{{item.displayName}}</a></li>
      </ng-container>
      <!--<li><a href="#">Link <span class="sr-only">(current)</span></a></li>-->
      <!-- <li *ngIf="hasAccess('admin')"><a style="color:white" target="_blank" href="{{adminUrl}}">Admin</a></li> -->
      <!-- <li *ngIf="hasAccess('admin')"><a style="color:white" [routerLink]="['/admin/resources']">Resource Admin</a></li> -->
      <!-- <li *ngIf="hasAccess('resource-feed')"><a style="color:white" [routerLink]="['/resources']">Resources</a></li>
      <li *ngIf="hasAccess('admin')"><a style="color:white" [routerLink]="['/admin']">Admin</a></li>  -->
    </ul>
    <div class="user-profile-block">
      <user-profile-menu></user-profile-menu>
    </div>
  </div><!-- /.container-fluid -->
</nav>

<app-home-page-search #searchComponent (closed)="onSearchClosed($event)" [hidden]="!showSearchPage" [searchText]="searchText"></app-home-page-search>

<router-outlet *ngIf="!showSearchPage"></router-outlet>

<synapze-app-footer></synapze-app-footer>
