
// tslint:disable-next-line:max-line-length
import { Component, OnInit, Input, Output, NgZone, NgModuleFactoryLoader, SystemJsNgModuleLoader, COMPILER_OPTIONS, CompilerFactory, Compiler, ViewChild, ViewContainerRef, Injector } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { SynapzeToastrService } from 'src/app/components/synapze-shareable-components/toastr/toastr.service';
import { TranslateService } from '@ngx-translate/core';
import { AppConfigService } from 'src/app/services/app-config/app-config.service';
import { HomePageSearchService } from 'src/app/services/homepage/home-page-search.service';
import { HomePageSearchParams } from 'src/app/models/homepage/homepageSearchParams';
import { dataGridModel } from 'src/app/models/dataGridModel';
import { HomePageSearchResult } from 'src/app/models/homepage/homepageSearchResults';
import { MenuItem } from 'primeng/api';
import { TenantService } from 'src/app/services/tenant/tenant.service';
import { SearchResponseItem } from 'src/app/models/search';
import { EventEmitter } from '@angular/core';
import { BaseComponent } from 'src/app/base/base.component';
import { ContentLoaderModel, LoaderType } from 'src/app/components/synapze-shareable-components/content-loader/content-loader.component';

// Needed for the new modules
import * as AngularCore from '@angular/core';
import * as AngularCommon from '@angular/common';
import * as AngularRouter from '@angular/router';
import * as BrowserAnimations from '@angular/platform-browser/animations';
import { createCompiler } from '../../dashboard/dashboard.component';
import { JitCompilerFactory } from '@angular/platform-browser-dynamic';
import { DynamicComponentLoader } from 'src/app/components/dynamic-component-loader/dynamic-component-loader.service';

declare var SystemJS: any;

@Component({
  selector: 'app-home-page-search',
  templateUrl: './home-page-search.component.html',
  styleUrls: ['./home-page-search.component.css'],
  providers: [
    {
      provide: NgModuleFactoryLoader,
      useClass: SystemJsNgModuleLoader
    },
    { provide: COMPILER_OPTIONS, useValue: {}, multi: true },
    { provide: CompilerFactory, useClass: JitCompilerFactory, deps: [COMPILER_OPTIONS] },
    { provide: Compiler, useFactory: createCompiler, deps: [CompilerFactory] }

  ]
})
export class HomePageSearchComponent extends BaseComponent implements OnInit {

  // private readonly EMPTYFORM = {
  //  pageSize: [16],
  //  pageNo: [1],
  //  displayAll: [false],
  //  sortColumns: [[]],
  //  searchParameters: this.formBuilder.group({
  //    searchText: [''],
  //    assetTypeCode: [''],
  //    category: [''],
  //    subcategory: [''],
  //    duration: ['']
  //  })
  // };

  homepageSearchForm: FormGroup;
  private searchParams: dataGridModel<HomePageSearchParams>;

  public set results(results: Array<SearchResponseItem>) {
    this._results = results;
    if (results.length === 0 && this.container) {
      this.container.clear();
    }
  }

  public get results() {
    return this._results;
  }

  public get isContainerEmpty() {
    return !this.container || this.container.length === 0;
  }

  _results: Array<SearchResponseItem> = [];

  @ViewChild('searchwidgets', { read: ViewContainerRef }) container: ViewContainerRef;

  @Output() closed = new EventEmitter();

  _searchText: string;
  @Input('searchText')
  public set searchText(searchText: string) {
    this._searchText = searchText;
    if (this.homepageSearchForm !== undefined) {
      this.homepageSearchForm.controls['searchtext'].setValue(searchText);
    }
  }
  public get searchText(): string {
    return this._searchText;
  }

  public get list(): any[] {

    if (this.activeTabItem === undefined) {
      return [];
    }
    if (this.activeTabItem.id === undefined || this.activeTabItem.id == null) {
      const allModule = this.results.find(r => r.moduleName === 'all');
      return allModule === undefined ? [] : allModule.items;
    }
    const moduleList = this.results.find(r => r.moduleName === this.activeTabItem.id);
    return moduleList === undefined ? [] : moduleList.items;
  }

  tabItems: MenuItem[];
  activeTabItem: MenuItem;
  private browserLang: string;
  statusText = 'not reached';
  isLoading = false;
  minCharacterLength = 3;

  constructor(private router: Router,
    private toastr: SynapzeToastrService,
    private readonly translate: TranslateService,
    private homepageSearchService: HomePageSearchService,
    private appConfigService: AppConfigService,
    private tenantService: TenantService,
    private lc: NgZone,
    private injector: Injector,
    private loader: NgModuleFactoryLoader,
    private dynamicComponentLoader: DynamicComponentLoader) {
      super();
      this.browserLang = translate.getBrowserLang();
      // this.lang$ = new Observable(browserLang);

      this.searchParams = new dataGridModel<HomePageSearchParams>();
      this.searchParams.searchParameters = new HomePageSearchParams();
      this.searchParams.searchParameters.locales = [this.browserLang];
      this.searchParams.pageSize = 30;
      this.searchParams.pageNo = 1;
      this.searchParams.searchParameters.status = ['PUBLISHED'];
      this.searchParams.sortColumns = [];

  }

  ngOnInit() {

    window.onscroll = () => {
      if (this.results && this.results.length !== 0) {
        let status = 'not reached';
        const windowHeight = 'innerHeight' in window ? window.innerHeight
          : document.documentElement.offsetHeight;
        const body = document.body, html = document.documentElement;
        const docHeight = Math.max(body.scrollHeight,
          body.offsetHeight, html.clientHeight,
          html.scrollHeight, html.offsetHeight);
        const windowBottom = windowHeight + window.pageYOffset;
        if (windowBottom >= docHeight) {
          status = 'bottom reached';
        }
        this.lc.run(() => {
          this.statusText = status;

          if (this.statusText === 'bottom reached' && !this.isLoading) {
            this.searchModule(this.activeTabItem.id, this.searchText);
          }
        });
      }
    };
    this.minCharacterLength = this.appConfigService.get().tenantConfig.minimumGlobalSearchFieldLength;
    this.homepageSearchForm = new FormGroup({
      searchtext: new FormControl(this.searchText, [Validators.required, Validators.minLength(this.minCharacterLength)]),
      assetTypeCode: new FormControl([])
    });

    this.homepageSearchService.getTenantModules().subscribe(modules => {
      this.tabItems = [{
        label: 'ALL',
        command: (event) => {
          this.activeTabItem = event.item;
          this.searchModule(event.item.id);
        }
      }];
      this.tabItems = this.tabItems.concat(modules.filter(moduleInfo => moduleInfo.moduleCode !== 'ADMIN').map(moduleInfo => {
        const menuItem: MenuItem = {
          label: moduleInfo.moduleName.toLocaleUpperCase(),
          command: (event) => {
            this.activeTabItem = event.item;
            this.searchModule(event.item.id);
          },
          id: moduleInfo.moduleCode
        };
        return menuItem;
      }));
      this.activeTabItem = this.tabItems[0];
    });
  }

  onSearchSubmit() {
    this.results = [];
    this.searchText = this.homepageSearchForm.controls['searchtext'].value;
    this.searchModule(this.activeTabItem.id, this.searchText);
  }

  get contentLoadingModel(): ContentLoaderModel {
    return <ContentLoaderModel>{ state: this.uiModel.state, loaderType: LoaderType.COMPONENT };
  }

  onAssetTypeChanged() {
    this.onSearchSubmit();
  }

  onClearSearchInput() {
    this.homepageSearchForm.reset();
    this.onSearchSubmit();
  }

  searchModule(moduleCode: string, searchText: string = null) {
    if (this.isLoading) {
      return;
    }

    this.searchParams.searchParameters.assetTypeCode = this.homepageSearchForm.controls['assetTypeCode'].value;

    if (this.searchParams.searchParameters.searchText !== searchText) {
      this.searchParams.searchParameters.searchText = searchText == null ? this.searchText : searchText;
      this.results = [];
    }

    if (moduleCode == null || moduleCode === undefined) {
      const allResults = this.results.find(item => item.moduleName === 'all');
      this.searchParams.pageNo = allResults === undefined ? 1 : allResults.pageNo + 1;
      if (this.activeTabItem.id !== undefined) {
        this.activeTabItem = this.tabItems[0];
      }
    } else {
      const moduleResults = this.results.find(item => item.moduleName === moduleCode);
      this.searchParams.pageNo = moduleResults === undefined ? 1 : moduleResults.pageNo + 1;
    }
    this.getResults(moduleCode, searchText);
  }

  getResults(moduleCode: string, searchText: string = null): void {

    this.isLoading = true;
    this.uiModel.pending();
    this.searchParams.searchParameters.moduleCodes = (moduleCode === undefined || moduleCode == null) ? [] : [moduleCode];
    this.homepageSearchService.search<HomePageSearchParams>(this.searchParams).subscribe(searchResults => {
      this.uiModel.ready();
      this.isLoading = false;
      if (searchResults != null && searchResults.result != null) {
        if (this.searchParams.pageNo === 1) {
          this.results = [];
        }

        if (moduleCode === undefined || moduleCode == null) {
          searchResults.result.forEach(searchResult => {
            const allResults = this.results.find(result => result.moduleName === 'all');
            if (allResults) {
              allResults.items = allResults.items.concat(searchResult.items.map(item => this.mapToHomepageResult(searchResult, item)));
              allResults.pageNo = this.searchParams.pageNo;
            } else {
              this.results.push({
                items: searchResult.items.map(item => this.mapToHomepageResult(searchResult, item)),
                pageNo: 1,
                moduleName: 'all'
              });
            }
          });
        } else {
          const items = searchResults.result[0];
          if (items != null && items.items != null) {
            const moduleResults = this.results.find(result => result.moduleName === moduleCode);
            if (moduleResults !== undefined) {
              // tslint:disable-next-line:max-line-length
              // moduleResults.items = moduleResults.items.concat(moduleResults.moduleName == 'RESOURCELIBRARY' ? items.items : items.items.map(item => this.mapToHomepageResult(moduleResults, item)));
              moduleResults.items = moduleResults.items.concat(items.items.map(item => this.mapToHomepageResult(moduleResults, item)));

              moduleResults.pageNo = this.searchParams.pageNo;
            } else {
              this.results.push({
                // tslint:disable-next-line:max-line-length
                // items: moduleCode == 'RESOURCELIBRARY' ? items.items : items.items.map(item => this.mapToHomepageResult({ moduleName: moduleCode }, item)),
                items: items.items.map(item => this.mapToHomepageResult({ moduleName: moduleCode }, item)),
                pageNo: 1,
                moduleName: moduleCode
              });
            }
          }
        }
      }
    });
  }

  private mapToHomepageResult(searchResult: SearchResponseItem, item: any): HomePageSearchResult {
    const elt = new HomePageSearchResult();
    switch (searchResult.moduleName) {
      case 'ASSESSMENT':
        {
          this.dynamicComponentLoader
            .getComponentFactory<any>('assessmentHomePageSearch-widget')
            .subscribe({
              next: componentFactory => {
                if (!this.container) {
                  return;
                }

                const ref = this.container.createComponent(componentFactory);
                ref.instance.assessmentSet = item;
                ref.changeDetectorRef.detectChanges();



              },
              error: err => {
                console.warn(err);
              }
            });
          // tslint:disable-next-line:max-line-length
          // var assessment = item.assessments.find(assessment => assessment.locale.toLocaleLowerCase().startsWith(this.browserLang.toLocaleLowerCase()));
          // elt.title = assessment == undefined ? item.name : assessment.title;
          // elt.preview = assessment == undefined ? '' : assessment.description;
          // elt.duration = item.duration;
          // elt.durationType = item.duration!=null && item.durationUnit != null ? item.durationUnit.toLocaleUpperCase().charAt(0) : null;
          // elt.link
          break;
        }
      case 'RESOURCELIBRARY':
        {
          this.dynamicComponentLoader
            .getComponentFactory<any>('resourceHomePageSearch-widget')
            .subscribe({
              next: componentFactory => {
                if (!this.container) {
                  return;
                }

                const ref = this.container.createComponent(componentFactory);
                ref.instance.asset = item;
                ref.changeDetectorRef.detectChanges();



              },
              error: err => {
                console.warn(err);
              }
            });
          // tslint:disable-next-line:max-line-length
          // elt.title = item.locale.toLocaleLowerCase().startsWith(this.browserLang.toLocaleLowerCase()) ? item.assetName : item.resource.resourceName;
          // tslint:disable-next-line:max-line-length
          // elt.preview = item.locale.toLocaleLowerCase().startsWith(this.browserLang.toLocaleLowerCase()) == undefined ? item.resource.description : item.description;
          // elt.categories = item.resource.resourceCategories;
          // elt.duration = item.assetAttachments != null && item.assetAttachments.length > 0 ? item.assetAttachments[0].duration : null;
          // tslint:disable-next-line:max-line-length
          // elt.durationType = item.assetAttachments != null && item.assetAttachments.length > 0 ? item.assetAttachments[0].durationType : null;
          // elt.link
        }
        break;
      default:
    }
    return elt;
  }

  // @HostListener("window:scroll", [])
  // onScroll(): void {
  //  if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
  //    // you're at the bottom of the page
  //    console.log('Bottom reached')
  //  }
  // }

  close() {
    this.searchText = null;
    this.homepageSearchForm.reset();
    this.results = [];
    this.closed.emit();
  }


}
