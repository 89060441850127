import { Component, OnInit, ViewChild, ViewContainerRef, Injector, NgModuleFactoryLoader, SystemJsNgModuleLoader, Compiler, COMPILER_OPTIONS, CompilerFactory } from '@angular/core';
import { JitCompilerFactory } from '@angular/platform-browser-dynamic';

import { Router, Route } from '@angular/router';
import { DynamicComponentLoader } from 'src/app/components/dynamic-component-loader/dynamic-component-loader.service';
import { TenantService } from 'src/app/services/tenant/tenant.service';
import { TenantDashboardWidgetModel } from 'src/app/models/tenantModel';
import { Observable, BehaviorSubject } from 'rxjs';
import { Http } from '@angular/http';

// Needed for the new modules
import * as AngularCore from '@angular/core';
import * as AngularCommon from '@angular/common';
import * as AngularRouter from '@angular/router';
import * as BrowserAnimations from '@angular/platform-browser/animations';

import { map } from 'rxjs/operators';
import { resolve } from 'dns';

declare var SystemJS: any;

export function createCompiler(compilerFactory: CompilerFactory) {
  return compilerFactory.createCompiler();
}

@Component({
  templateUrl: 'dashboard.component.html',
  providers: [
    {
      provide: NgModuleFactoryLoader,
      useClass: SystemJsNgModuleLoader
    },
    { provide: COMPILER_OPTIONS, useValue: {}, multi: true },
    { provide: CompilerFactory, useClass: JitCompilerFactory, deps: [COMPILER_OPTIONS] },
    { provide: Compiler, useFactory: createCompiler, deps: [CompilerFactory] }

  ]
})
export class DashboardComponent implements OnInit {

  @ViewChild('widgets', { read: ViewContainerRef }) container: ViewContainerRef;
  private compiler: Compiler;
  existingRoutes: BehaviorSubject<Route[]>;

  constructor(private injector: Injector,
    private loader: NgModuleFactoryLoader,
    private dynamicComponentLoader: DynamicComponentLoader,
    private tenantService: TenantService,
    private http: Http,
    private router: Router
    ) {

    this.compiler = this.injector.get(Compiler);
  }

  ngOnInit(): void {
    this.load();

  }



  load() {
    
    this.tenantService.getTenantDashboardWidgets().subscribe(widgets => {
      widgets.forEach(widget => {
        if (widget.widgetInfo.isExternalModule) {
          this.loadModuleSystemJS(widget).then(
            result => {
            }
          );
        }
        else {
          this.dynamicComponentLoader
            .getComponentFactory<any>(widget.widgetInfo.componentToInvoke)
            .subscribe({
              next: componentFactory => {
                if (!this.container) {
                  return;
                }

                const ref = this.container.createComponent(componentFactory);
                ref.instance.metaData = widget.metadata;
                ref.changeDetectorRef.detectChanges();



              },
              error: err => {
                console.warn(err);
              }
            });
        }

      });

      

    });




    //this.dynamicComponentLoader
    //  .getComponentFactory<any>('favoriteAndTrending-widget')
    //  .subscribe({
    //    next: componentFactory => {
    //      console.log("ENTERRED");

    //      if (!this.container) {
    //        return;
    //      }

    //      const ref = this.container.createComponent(componentFactory);
    //      ref.instance.testString = "From outside";
    //      ref.changeDetectorRef.detectChanges();



    //    },
    //    error: err => {
    //      console.warn(err);
    //    }
    //  });

    //this.loader.load('src/app/modules/resourceLibrary/views/widgets/favoriteAndTrending/widgets-favoriteAndTrending-favoriteAndTrending-widget-module-ngfactory.js#FavoriteAndTrendingWidgetModuleNgFactory').then((factory) => {

    //  const exports = {}; // this will hold module exports

    //  const mwcf = this.compiler.compileModuleAndAllComponentsSync(exports['FavoriteAndTrendingWidgetModule']);

    //  const componentFactory = mwcf.componentFactories
    //    .find(e => e.selector === 'favorite-and-trending-widget'); // find the entry component

    //  if (componentFactory) {
    //    this.container.clear();

    //    const componentRef = this.container.createComponent(componentFactory);
    //    //componentRef.instance.data = 'Some Data';
    //  }


    //});
  }



  loadModuleSystemJS(model: TenantDashboardWidgetModel): Promise<any> {
    let url = model.widgetInfo.pathToModuleFile;
    //SystemJS.config({ transpiler: 'transpiler-module' })
    SystemJS.set('@angular/core', SystemJS.newModule(AngularCore));
    SystemJS.set('@angular/common', SystemJS.newModule(AngularCommon));
    SystemJS.set('@angular/router', SystemJS.newModule(AngularRouter));
    SystemJS.set('@angular/platform-browser/animations', SystemJS.newModule(BrowserAnimations));
    //SystemJS.set('@clr/angular', SystemJS.newModule(AngularClarity));
    // now, import the new module
    return SystemJS.import(`${url}`).then((module) => {

      return this.compiler.compileModuleAndAllComponentsAsync(module[model.widgetCode]).then(compiled => {
        const componentFactory = compiled.componentFactories
          .find(e => e.selector === model.widgetInfo.componentToInvoke); // find the entry component
            const componentRef = this.container.createComponent(componentFactory);
        return module;
      });
    });
  }




  ngAfterViewInit() {

    //widgets-favoriteAndTrending-favoriteAndTrending-widget-module-ngfactory




    //this.loader.load('src/app/modules/resourceLibrary/views/widgets/favoriteAndTrending/favoriteAndTrending.widget.module').then((factory) => {
    //  const module = factory.create(this._injector);
    //  const r = module.componentFactoryResolver;

    //  this.compiler.compileModuleAndAllComponentsSync()

    //  const cmpFactory = r.resolveComponentFactory(AComponent);

    //  // create a component and attach it to the view
    //  const componentRef = cmpFactory.create(this._injector);
    //  this.container.insert(componentRef.hostView);
    //})

    //this.loader.load('src/app/modules/resourceLibrary/views/widgets/favoriteAndTrending/favoriteAndTrending.widget.module.ts#NMLLazyModule').then((factory) => {
    //  const module = factory.create(this._injector);
    //  const resolver = module.componentFactoryResolver;
    //  const cmpFactory = resolver.resolveComponentFactory(NMLBComponent);
    //  this._container.createComponent(cmpFactory);
    //});
  }


}
