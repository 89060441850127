import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';

import { Permission } from 'src/app/vos/permission/permission';
import { AppConfigService } from 'src/app/services/app-config/app-config.service';
import { Constants } from '../../shared/shared.constants';
import { StorageService } from 'ngx-webstorage-service';
import { CORE_SESSION_STORAGE } from '../storage/storage.service';

/**
 * Config class to be wired into an injector.
 * @see CoreModule#forRoot
 * @see https://angular.io/guide/dependency-injection#optional-dependencies
 */
export class PermissionsServiceConfig {
  uri = '';
}

@Injectable()
/**
 * Service class.
 */
export class PermissionsService {

  /**
   * Path uri.
   * @type {string}
   * @private
   */
  private _uri = 'https://c08dc89a-c8de-414d-bdf9-b85fb2958be0.mock.pstmn.io/api/';
  //private _uri = 'http://localhost:5000/api/UserProfile/UserPermissions';


  /**
   * Url to endpoint api.
   * @type {string}
   */
  private endpoint = '';

  private _appConfigService: AppConfigService;

  /**
   * Endpoint request headers.
   * @type {HttpHeaders}
   */
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  /**
   * Component constructor and DI injection point.
   * @param {HttpClient} http
   * @param {PermissionsServiceConfig} config
   */
  constructor(private http: HttpClient,
    @Optional() appConfigService: AppConfigService,
    @Optional() config: PermissionsServiceConfig,
    @Inject(CORE_SESSION_STORAGE) private sessionStorage: StorageService) {
    if (config) {
      this._uri = config.uri;
    }

    this._appConfigService = appConfigService;
  }

  /**
   * Pulls a list of Permission objects.
   * @returns {Observable<Permission[]>}
   */
  list(endpoint: string): Observable<Permission[]> {
    //if (this._appConfigService) {
    //  this._uri = this._appConfigService.get().apiEndpoints.permissionsService;
    // }
    //return this.http.get<Permission[]>(`${this._uri}${this.endpoint}`);
    var url = `${endpoint}/api/UserProfile/UserPermissions`;
    return this.http.get<Permission[]>(`${url}`);
  }

  checkUserPermission(permission: string) {
    var permissions = this.userPermissions();

    return permissions.includes(permission);
  }

  userPermissions(): string[] {
    //HACK TO TEST SECURITY
    //return ["RESOURCELIBRARY_PUBLISH_RESOURCE"];
    var permissions: string[] = [];
    if (this.sessionStorage.get(Constants.PERMISSIONS_STORAGE_KEY)) {
      permissions = permissions.concat(JSON.parse(this.sessionStorage.get(Constants.PERMISSIONS_STORAGE_KEY)));
    }
    else {
      this.list(this._appConfigService.get().
        tenantConfig.permissionService.serviceUrl)
        .subscribe(permissions => {
          if (permissions != null) {
            permissions = permissions.filter(x => (x != null) && (x != ''));
            this.sessionStorage.set(Constants.PERMISSIONS_STORAGE_KEY, JSON.stringify(permissions));
          }
        });
    }
    return permissions;
  }

  checkUserPermissions(permissions: string[], allPermissions: boolean): boolean {


    var userPermissions = this.userPermissions();


    var accessGranted = false;
      if (allPermissions) {
        accessGranted = permissions.filter(perm => !userPermissions.includes(perm)).length == 0;
      }
      else {
        accessGranted = permissions.filter(perm => userPermissions.includes(perm)).length > 0;
      }
    return accessGranted;

  }


}
