
import { Router, ActivatedRoute, Params, DefaultUrlSerializer, UrlTree } from '@angular/router';
import { OnInit, Component } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';

@Component({
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css'],
  providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }],
})
/**
 * Page not found component. Good catchall.
 */
export class PageNotFoundComponent implements OnInit {
  constructor(
    private location: Location,
    private activatedRoute: ActivatedRoute) { }

  public isLogin: boolean

  ngOnInit() {
    // Note: Below 'queryParams' can be replaced with 'params' depending on your requirements
    this.isLogin = this.location.path(true).indexOf('id_token')!=-1;
  }
}
