<div class="form-catergorys" style="display: none;">
  <div class="container">
    <div class="text-center">
      <h1>
        Select your category preferences!
      </h1>
    </div>
    <p class="text-muted text-center" *ngIf="titleMessage">{{titleMessage}}</p>
    <p class="text-muted text-center">Please select between {{config.minCategoriesAllowed}} and {{config.maxCategoriesAllowed}} categories
      from the list below</p>


    <div class="row">
      <div class="col-sm-offset-2 col-sm-8">
        <form [formGroup]="form">
          <div class="row clearfix">
            <div class="col-sm-6" *ngFor="let category of getCategories(); index as i;" formArrayName="categories">
              <div class="checkbox" [formGroupName]="i">
                <label>
                  <input type="checkbox" formControlName="selected">
                  {{categories[i].categoryName}}
                </label>
              </div>
            </div>
          </div>

          <div class="error-container clearfix">
            <div class="alert alert-danger" role="alert" *ngIf="categoriesArray.errors && (form['submitted'])">
              <div *ngIf="categoriesArray.errors['min']">
                Please select at least {{config.minCategoriesAllowed}} categories
              </div>
              <div *ngIf="categoriesArray.errors['max']">
                You can not select more than {{config.maxCategoriesAllowed}} categories
              </div>
            </div>
          </div>
          <div class="text-right">
            <button type="button" class="btn btn-primary" (click)="submit(true)">Submit</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>


<form [formGroup]="form">
  <div class="legend">
    <i></i>
    <h4 class="title">RESOURCES</h4>

    <p>{{ 'core-common-preference_header' | translate: '{ defaultText : \'See the content that interests you most\' }' }}</p>

    <p class="text-muted text-center" *ngIf="titleMessage">{{titleMessage}}</p>

    <p>{{ 'core-common-preference_message' | translate: '{ defaultText : \'Tap the Categories that you want to see first in your feed.\' }' }}</p>
  </div>

  <div class="categories-container container-full">
    <div class="categories" formArrayName="categories">
      <div class="category" *ngFor="let category of categories; index as i;" [formGroupName]="i">
        <app-category-control formControlName="selected" categoryName="{{category.categoryId | categoryName | async}}"></app-category-control>
      </div>
    </div>
  </div>

  <div class="button-bar clearfix">
    <div class="status">
      {{getSelectedCategories()}}<span class="separator">/</span>{{config.maxCategoriesAllowed}}
    </div>
    <button type="button" class="btn btn-primary" [ngClass]="{'invalid': !form.valid}" (click)="submit(true)">{{ 'core-common-done_button' | translate: '{ defaultText : \'I m DONE\' }' }}</button>
  </div>

</form>
