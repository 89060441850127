import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ResourceCategoryModel, ResourceUserCategoryModel } from '../../../models/resourceCategoryModel';
import { CategoriesService } from '../../../services/categories.service';
import { Constants } from '../../../../../shared/shared.constants';

import { CategoriesValidator } from './categories.validator';
import { JsonPipe } from '@angular/common';
import { UserService } from 'src/app/services/user/user.service';
import { TranslateService } from '@ngx-translate/core';
import { CORE_SESSION_STORAGE } from 'src/app/services/storage/storage.service';
import { StorageService } from 'ngx-webstorage-service';

@Component({
  selector: 'app-preferences',
  templateUrl: './preferences.component.html',
  styleUrls: ['./preferences.component.css']
})
export class PreferencesComponent implements OnInit {

  @Input() overrideNavigation:boolean=false;

  @Output()  OnComplete = new EventEmitter();

  categoriesArray = this.formBuilder.array([], [CategoriesValidator({ min: 1, max: 5 })]);
  form: FormGroup = this.formBuilder.group({
    categories: this.categoriesArray
  });

  categories: ResourceCategoryModel[] = [];
  userCategories: ResourceUserCategoryModel[] = [];
  config: { minCategoriesAllowed?: number, maxCategoriesAllowed?: number, updateFrequencyPeriod?: number } = {};
  titleMessage = '';
  preference: string;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private categoriesService: CategoriesService,
    private router: Router,
    private snackBar: MatSnackBar,
    private userService: UserService,
    private readonly translate: TranslateService,
    @Inject(CORE_SESSION_STORAGE) private sessionStorage: StorageService
  ) { }

  ngOnInit() {
    this.preference = this.userService.currentUser().languagePreference;
    this.categories = this.route.snapshot.data['categories'];
    this.userCategories = this.route.snapshot.data['userCategories'];
    this.config = this.route.snapshot.data['config'];

    this.config.maxCategoriesAllowed = this.config.maxCategoriesAllowed || this.categories.length;
    this.form.get('categories').setValidators(
      [CategoriesValidator({
        min: this.config.minCategoriesAllowed || 1,
        max: this.config.maxCategoriesAllowed
      })]
    );
    this.form.updateValueAndValidity();
    this.categories.forEach(cat => this.addCategory(cat));
    const isBefore = this.userCategories.some(cat => {
      return moment().diff(moment(cat['updatedAt']), 'days') > this.config.updateFrequencyPeriod;
    });
    const isNewCategory = this.userCategories.some(cat => cat.isInserted);
    const isModified = this.userCategories.some(cat => cat.isModified);

    if (isBefore) {
      this.titleMessage = 'Would you like to update your category preferences?';
    }

    if (isNewCategory) {
      this.titleMessage = 'Some categories have been added, do you want to update?';
    }

    if (isModified) {
      this.titleMessage = `Some of your chosen categories have been removed/updated,
      please update your selection to maintain the minimum expectation`;
    }

  }

  public submit(validate:any) {
    this.form['submitted'] = true;
    if(validate!=null && validate==true){
    if (!this.form.valid) {
      this.showValidationErrors();
      return;
    }
  }
    const categoriesArray: any[] = this.form.value.categories;
    const categoriesIDs = categoriesArray.filter(cat => cat.selected).map(cat => ({ categoryId: cat.categoryId }));
    this.categoriesService.setUserCategories(categoriesIDs)
      .subscribe(results => {
        this.sessionStorage.set(Constants.CATEGORIES_UPDATED, 'true');
        if(!this.overrideNavigation)
        {
          this.router.navigate(['/resources']);
        }
        else{
        this.OnComplete.emit();
        }
        });


  }

  public getSelectedCategories() {
    const categories = this.getCategories();
    return categories.filter(control => control.value.selected).length;
  }

  public getCategories() {
    const categories: FormArray = this.form.get('categories') as FormArray;
    return categories.controls;
  }

  private showValidationErrors() {
    let message = ``;
    if (this.form.get('categories') && this.form.get('categories').errors['min']) {
      var min = this.config.minCategoriesAllowed;
      this.translate.get(["core-common-min_category_preference"], { defaultText: `Please select ${this.config.minCategoriesAllowed} categories to continue` })
        .subscribe((res: string) => {
          message = res["core-common-min_category_preference"];
        });
      //message = `Please select ${this.config.minCategoriesAllowed} categories to continue`;
    }
    if (this.form.get('categories') && this.form.get('categories').errors['max']) {
      this.translate.get(["core-common-max_category_preference"], { defaultText: `Please select up to ${this.config.maxCategoriesAllowed} categories to continue` })
        .subscribe((res: string) => {
          message = res["core-common-max_category_preference"];
        });
      //message = `Please select up to ${this.config.maxCategoriesAllowed} categories to continue`;
    }
    this.snackBar.open(
      message,
      '',
      { duration: 2000, verticalPosition: 'top' });
  }

  private addCategory(category: ResourceCategoryModel): void {
    const items: FormArray = this.form.get('categories') as FormArray;
    const isSelected = !!this.userCategories.find(cat => cat.categoryId === category.categoryId);
    const categoryGroup = this.formBuilder.group({
      selected: isSelected,
      ...category
    });
    items.push(categoryGroup);
  }

}
