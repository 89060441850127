import { Injectable, Inject } from '@angular/core';
import { CanActivate, Resolve, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import * as moment from 'moment';
import { forkJoin, of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { CategoriesService } from '../../../services/categories.service';
import { Constants } from '../../../../../shared/shared.constants';
import { TenantService } from 'src/app/services/tenant/tenant.service';
import { CORE_SESSION_STORAGE } from 'src/app/services/storage/storage.service';
import { StorageService } from 'ngx-webstorage-service';


@Injectable({
  providedIn: 'root'
})
export class PreferencesResolver implements Resolve<any> {

  constructor(private tenantService: TenantService) { }

  resolve() {
    return this.tenantService.getCategories();
  }
}


@Injectable({
  providedIn: 'root'
})
export class PreferencesGuard implements CanActivate {

  userCategories$ = this.getUserCategories();
  redirectRouteOverride:string;
  constructor(private categoriesService: CategoriesService,
    private router: Router,
    @Inject(CORE_SESSION_STORAGE) private sessionStorage: StorageService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean>|Observable<boolean>
  {
    this.redirectRouteOverride = route.data["redirectOverride"] as string;
    // in case user skipped the preference, we do not want to show welcome page again.
    var preferenceSkipped = this.sessionStorage.get(Constants.WELCOME_SKIPPED);
    // console.log(this.userCategories$);
    if(preferenceSkipped== "true"){
      return of(true);
    }
    else
    {
      return new Promise((resolve, reject) => {
        forkJoin(
          this.userCategories$,
          this.categoriesService.getCategoriesConfiguration()
        )
          .pipe(
            map(response => ({ userCategories: response[0], config: response[1] }))
          )
          .subscribe(
            (response) =>
          {
            const userCategories = response.userCategories;
            if (this.checkEmptyCategories(userCategories)) {
              // console.log('redirecting cause categories are empty');
              this.redirectUser();
              return resolve(false);
            }
            if (this.checkFrequencyPeriod(userCategories, response.config))
            {
              // console.log('redirecting... categories are old enough to redirect');
              this.redirectUser();
              return resolve(false);
            }
            if (this.checkUpdatedCategories(userCategories)) {
              // console.log('redirecting... one or more categories were updated');
              this.redirectUser();
              return resolve(false);
            }
            if (this.checkNewCategories(userCategories)) {
              // console.log('redirecting... one or more categories were inserted');
              this.redirectUser();
              return resolve(false);
            }
            resolve(true);
          });
      });
    }
  }

  private redirectUser() {
    // console.log(this.redirectRouteOverride);
    if(this.redirectRouteOverride!=null&& this.redirectRouteOverride !=''){
      // console.log("Redirecting to "+this.redirectRouteOverride);
      this.router.navigate([this.redirectRouteOverride]);
    }
      else{
      this.router.navigate(['/preferences']);
      }
  }

  private checkEmptyCategories(userCategories: any[]): boolean {
    return userCategories == null || userCategories.length === 0;
  }

  private checkFrequencyPeriod(categories: any[], config: any): boolean {
    return categories.some(cat => {
      return moment().diff(moment(cat['updatedAt']), 'days') > config.updateFrequencyPeriod;
    });
  }

  private checkUpdatedCategories(categories: any[]): boolean {
    return categories.some(cat => cat['isModified']);
  }

  private checkNewCategories(categories: any[]): boolean {
    return categories.some(cat => cat['isInserted']);
  }

  private getUserCategories(): Observable<any[]> {
    return this.categoriesService.getUserCategories();
  }
}
