import { Injectable, Optional, Inject } from '@angular/core';
import { AppConfigService } from '../app-config/app-config.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { dataGridModel } from 'src/app/models/dataGridModel';
import { retry, flatMap } from 'rxjs/operators';
import { Constants } from 'src/app/shared/shared.constants';
import { GroupModel, GroupSearchModel } from 'src/app/models/groupModel';
import { CORE_SESSION_STORAGE } from '../storage/storage.service';
import { StorageService } from 'ngx-webstorage-service';

@Injectable({
  providedIn: 'root'
})
export class GroupsService {


  /**
     * Path uri.
     * @type {string}
     * @private
     */
  private _uri = '/api/group';

  /**
   * Url to endpoint api.
   * @type {string}
   */
  private endpoint = '';

  private _appConfigService: AppConfigService;

  /**
   * Endpoint request headers.
   * @type {HttpHeaders}
   */
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  /**
   * Component constructor and DI injection point.
   * @param {HttpClient} http
   */
  constructor(private http: HttpClient,
    @Optional() appConfigService: AppConfigService,
    @Inject(CORE_SESSION_STORAGE) private sessionStorage: StorageService) {
    this._appConfigService = appConfigService;
    if (this._appConfigService) {
      this._uri = `${this._appConfigService.get().tenantConfig.authService.serviceUrl}` + this._uri;
    }
  }

  /**
   * Pulls a list of Roles objects.
   * @returns {Observable<RoleModel[]>}
   */
  list<T>(searchParameters: dataGridModel<T>): Observable<GroupModel[]> {
    var endpoint = '/search';
    return this.http.post<GroupModel[]>(`${this._uri}${endpoint}`, searchParameters);
    //return of(GroupsService.groups);
  }

  listAll(): Observable<GroupModel[]> {
    var persistedData = this.sessionStorage.get(Constants.TENANT_GROUPS);
    if (persistedData !== undefined && persistedData != null && persistedData != '') {
      return of(<GroupModel[]>JSON.parse(persistedData));
    }
    else {
      var endpoint = '/search';
      var searchParams = new dataGridModel<GroupSearchModel>();
      searchParams.displayAll = true;
      return this.http.post<GroupModel[]>(`${this._uri}${endpoint}`, searchParams)
        .pipe(flatMap(locs => {
          if (locs != null)
            this.sessionStorage.set(Constants.TENANT_GROUPS, JSON.stringify(locs));

          return of(locs);
        }));


    }
  }

  listAllActive(): Observable<GroupModel[]> {
    var persistedData = this.sessionStorage.get(Constants.TENANT_GROUPS);
    if (persistedData !== undefined && persistedData != null && persistedData != '') {
      return of(<GroupModel[]>JSON.parse(persistedData));
    }
    else {
      var endpoint = '/search';
      var searchParams = new dataGridModel<GroupSearchModel>();
      searchParams.searchParameters = new GroupSearchModel();
      searchParams.searchParameters.status = 'ACTIVE';
      searchParams.displayAll = true;

      return this.http.post<GroupModel[]>(`${this._uri}${endpoint}`, searchParams)
        .pipe(flatMap(locs => {
          if (locs != null)
            this.sessionStorage.set(Constants.TENANT_GROUPS, JSON.stringify(locs));

          return of(locs);
        }));


    }
  }


}
