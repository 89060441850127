<header class="synapze-header"  clickOutside (clickOutside)="hideSearch()">
  <div class="logo-area col-xs-12 col-md-2">
    <a [routerLink]="['/']">
      <div class="logo-img-area"></div>
      <!--<img class="logo-img" src="../../../../assets/css/assets/logo-pevaar.svg" />-->
    </a>
    <div class="mobile-nav-area">
      <div class="search-icon-area" >
        <!--<a (click)="toggleSearch()"><img src="../../../assets/css/assets/icn-nav-search.png" /></a>-->
        <a [routerLink]="" (click)="toggleSearch()">
          <!--<img src="../../../assets/css/assets/icn-nav-search.png" />-->
          <div class="nav-search-icon"></div>
        </a>
      </div>
      <div class="notification-area">
        <!--<img src="../../../../assets/css/assets/icn-nav-alert.png" alt="" />-->
        <div class="nav-notification-icon"></div>
      </div>
      <div class="mobile-user-area">
        <user-profile-menu [infoVisible]="true" [adminView]="adminView" class="fullDisplayMobile"></user-profile-menu>
      </div>
      <div class="mobile-hamburger-icon"> &#9776; </div>
      <div class="mobile-menu-content">

        <user-profile-menu [infoVisible]="true" [mobileView]="true" [adminView]="adminView" class="fullDisplayMobile"></user-profile-menu>



        <div class="mobile-links-area">

          <div *ngFor="let item of items">
            <button (click)="goto(item.routerLink)" class="accordion">

              {{'core-common-user_menu_' + item.label| lowercase | translate : '{defaultText: \''+ item.label +'\'}' }}

            </button>
            <!--<div class="panel">
        <a href="#" title="TITLE TEXT">subMenu Item 1</a>
        <a href="#" title="TITLE TEXT">subMenu Item 1</a>
        <a href="#" title="TITLE TEXT">subMenu Item 1</a>
      </div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="links-area col-md-7" id="nav-main">
    <ul id="main">
      <li class="change" *ngFor="let item of items" [ngClass]="{'active': isActive(item) }">
        <a [routerLink]="[item.routerLink]" title="{{item.label}}" *ngIf="!itemHasChildren(item)"
          (click)="changeMenu(item)">
          {{'core-common-user_menu_' + item.label | lowercase | translate : '{defaultText: \''+ item.label +'\'}' }}
        </a>
        <a title="{{item.label}}" *ngIf="itemHasChildren(item)" (click)="changeMenu(item)">
          {{'core-common-user_menu_' + item.label | lowercase | translate : '{defaultText: \''+ item.label +'\'}' }}
        </a>
      </li>
    </ul>
  </div>
  <div class="search-area col-md-2">
    <div class="search-icon-area"  *ngIf="!adminView">
      <a [routerLink]="" (click)="toggleSearch()" clickOutside>
        <!--<img src="../../../assets/css/assets/icn-nav-search.png" />-->
        <div class="nav-search-icon"></div>
      </a>
    </div>
    <div class="notification-area"  *ngIf="!adminView">
      <div class="nav-notification-icon"></div>
      <!--<img src="../../../../assets/css/assets/icn-nav-alert.png" alt="" />-->
    </div>
  </div>
  <div class="user-area col-md-1">
    <user-profile-menu [infoVisible]="true" [adminView]="adminView" class="fullDisplayMobile"></user-profile-menu>
  </div>


  <form [formGroup]="homepageSearchForm">
    <div class="search-area main-page-search" *ngIf="isSearchToggled">
      <button type="submit" class="search-link" (click)="onSearchSubmit()" [disabled]="homepageSearchForm.invalid"><div>&#x2315;</div></button>
      <input id="homepageSearchInput" type="text" placeholder="{{'core-homepage-search_searchplaceholder' | translate : '{ defaultText: \'Tap text to search\'}'}}"
             name="homepageSearch" formControlName="searchtext" style="width:50vw">
      <button class="btn-link clear-link" style="color:inherit!important" (click)="onClearSearchInput()">{{'core-homepage-search_searchplaceholder_clear' | translate : '{ defaultText: \'clear\'}'}}</button>

      <br />

      <div *ngIf="homepageSearchForm.controls.searchtext.invalid && (homepageSearchForm.controls.searchtext.dirty)"
           class="alert alert-danger">
        {{ 'core-homepagesearch-search_field_length' | translate : '{ defaultText : \'Search text character length must be at least \' }' }} {{minCharacterLength}}
      </div>

    </div>
  </form>

  <div class="subMenu" *ngIf="selectedItem">
    <div class="row">
      <div class="col-md-12 subMenuContent">
        <div class="row">
          <div class="col-md-7 col-md-offset-2">
            <ul>
              <li *ngFor="let item of selectedItem.items">
                <a [routerLink]="[item.routerLink]" title="{{item.label}}">
                  {{'core-common-user_menu_' + item.label| lowercase | translate : '{defaultText: \''+ item.label +'\'}' }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>


</header>


<!--<div class="nav-bar">
  <div class="containerNg">
    <div class="company-logo">
      <a [routerLink]="['/']">
        <img src="../../../../assets/css/assets/logo-pevaar.svg" />
      </a>
    </div>
    <div class="action-block">
      <a (click)="toggleSearch()"><img src="../../../assets/css/assets/icn-nav-search.png" /></a>
      <img src="../../../assets/css/assets/icn-nav-alert.png" />
    </div>
    <p-menubar [model]="items" [autoDisplay]="menuConfiguration.autoDisplay" styleClass="topMenuBar">
      <user-profile-menu></user-profile-menu>
      <button class="btn btn-link visible-xs mobileMenuButton" type="button" (click)="toggleMobileMenu()">
        <div class="navbar-toggle">
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </div>
      </button>
    </p-menubar>
    <p-sidebar [(visible)]="mobileVisible" [fullScreen]="true" styleClass="mobile-menu">
      <user-profile-menu [infoVisible]="true" class="fullDisplayMobile"></user-profile-menu>
      <p-panelMenu [model]="items" styleClass="mobile-panel-menu"></p-panelMenu>
    </p-sidebar>
    <form [formGroup]="homepageSearchForm">
      <div class="search-area" style="position: absolute;left: 200px;top: 10px;background-color: white;" *ngIf="isSearchToggled">
        <button type="submit" class="search-link" (click)="onSearchSubmit()"><div>&#x2315;</div></button>
        <input id="homepageSearchInput" type="text" placeholder="{{'core-homepage-search_searchplaceholder' | translate : '{ defaultText: \'Tap text to search\'}'}}"
               name="homepageSearch" formControlName="searchtext" style="width:50vw">

        <button class="btn-link clear-link" style="color:inherit!important" (click)="onClearSearchInput()">{{'core-homepage-search_searchplaceholder_clear' | translate : '{ defaultText: \'clear\'}'}}</button>
      </div>
    </form>


  </div>

</div>-->
