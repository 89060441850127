export class HomePageSearchResults {
  moduleName: string;
  results: HomePageSearchResult[];
}

export class HomePageSearchResult {
  id: string;
  title: string;
  preview: string;
  link: string;
  categories: number[];
  duration: number;
  durationType: string;
  durationIcon: string;
}
