import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfigService } from '../app-config/app-config.service';
import { AppConfig } from 'src/app/vos/app-config/app-config';
import { UserModel, UserChangePassword, UserWelcomeStateModel } from '../../models/UserModel';
import { of, BehaviorSubject } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from 'src/app/shared/shared.constants';
import { Observable, Subject } from 'rxjs';
import { SynapzeCxOAuthserviceService } from 'src/app/auth/synapze-cx-oauthservice.service';
import { StorageService } from 'ngx-webstorage-service';
import { CORE_SESSION_STORAGE } from '../storage/storage.service';


@Injectable()
/**
 * Service class.
 */
export class UserService {

  static readonly CURRENT_USER_FETCHED_EVENT = "currentUserFetched";
  static readonly LANGUAGE_CHANGE_EVENT = "languageChangeEvent";
  /**
   * Path uri.
   * @type {string}
   * @private
   */
  private _uri = '';
  private subject = new Subject<any>();
  private imageUploaded = new BehaviorSubject('');
  currentMessage = this.imageUploaded.asObservable();
  
  /**
   * Url to endpoint api.
   * @type {string}
   */
  private endpoint = '/api/UserProfile';
  private config: AppConfig;
  /**
   * Endpoint request headers.
   * @type {HttpHeaders}
   */
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  public user: UserModel = null;

  /**
   * Component constructor and DI injection point.
   * @param {HttpClient} http
   * @param {AppConfigService} config
   */
  constructor(private http: HttpClient, configService: AppConfigService,
    private translate: TranslateService,
    private oauthService: SynapzeCxOAuthserviceService,
    @Inject(CORE_SESSION_STORAGE) private sessionStorage: StorageService) {
    if (configService) {
      this.config = configService.get();
      if (this.config != null)
        this._uri = this.config.tenantConfig.authService.serviceUrl;
    }
  }


  refreshImage(message: string) {
    this.imageUploaded.next(message)
  }

  /**
   * Pulls a single User object.
   * @returns {Observable<UserModel>}
   */
  getUserInfo(): Observable<UserModel> {
    if (this.user != null) {
      return of(this.user);
    } else {
      if (this.oauthService.HasValidToken()) {
        const url = `${this._uri}${this.endpoint}/GetCurrentUser`;
        return this.http.get<UserModel>(url)
          .pipe(flatMap(usr => {
            if (usr != null) {
              this.user = usr;
              if (usr.browsingLanguagePreference == null || usr.browsingLanguagePreference == "")
                this.sessionStorage.set(Constants.USER_BROWSER_LANGUAGE, usr.languagePreference);
              else
                this.sessionStorage.set(Constants.USER_BROWSER_LANGUAGE, usr.browsingLanguagePreference);

            }
            return of(usr);
          }));
      } else {
        return of(null);
      }
    }
  }

  forceUserRefresh() {
    this.user = null;
    this.getUserInfo().subscribe(result => this.user = result);
  }


  /**
   * Pulls a single User object.
   * @returns {Observable<String>}
   */
  changePassword(changePasswordModel: UserChangePassword): Observable<boolean> {

    const url = `${this._uri}${this.endpoint}/changepassword`;
    return this.http.post<boolean>(url, changePasswordModel);
  }


  /**
* Pulls a single User object.
* @returns {Observable<String>}
*/
  checkOldPassword(changePasswordModel: UserChangePassword): Observable<boolean> {

    const url = `${this._uri}${this.endpoint}/checkoldpassword`;
    return this.http.post<boolean>(url, changePasswordModel);

  }


  currentUser(): UserModel {
    if (this.user === null || this.user === undefined) {
      this.getUserInfo().subscribe(usr => {
        this.user = usr;
        this.subject.next({ event: UserService.CURRENT_USER_FETCHED_EVENT });
        
        if (this.user != null) {
           this.setUserBrowsingLanguage(this.user.browsingLanguagePreference);
        }
      });
    }

    return this.user;
  }

  events(): Observable<any> {
    return this.subject.asObservable();
  }

  setwelcomestate(welcomeState: UserWelcomeStateModel): Observable<boolean> {
    const url = `${this._uri}${this.endpoint}/setuserwelcomestate`;
    let result = this.http.post<boolean>(url, welcomeState);
    result.subscribe(res => {
      if (res) {
        this.user.firstPreferenceUpdated = welcomeState.firstPreferenceUpdated;
      }
    });

    return result;
  }

  checkUserEmailExists(userInfo: UserModel): Observable<boolean> {
    const url = `${this._uri}${this.endpoint}/checkemailexistance`;
    let result = this.http.post<boolean>(url, userInfo);
    return result;
  }

  updateUser(userModel: UserModel): Observable<any> {
    const urlUpdate = `${this._uri}${this.endpoint}/updateuserinfo`;
    let result = this.http.post<boolean>(urlUpdate, userModel);
    // result.subscribe(res=>{
    //   if(res == true)
    //   {
    //     const url = `${this._uri}${this.endpoint}/GetCurrentUser`;
    //       this.http.get<UserModel>(url)
    //                 .pipe(flatMap(usr => {
    //                   if (usr != null)
    //                   this.user = usr;
    //                   return of(usr);
    //                 }));
    //   }
    // });
    return result;
  }


  getUserBrowsingLanguage() {
    const currentLang = this.sessionStorage.get(Constants.USER_BROWSER_LANGUAGE);
    if (currentLang !== undefined && currentLang != null && currentLang != '') {
      return currentLang;
    } else {
      var user = this.currentUser();
      if (user != null && user.browsingLanguagePreference != null && user.browsingLanguagePreference != '')
        return user.browsingLanguagePreference;
      else 
        return this.translate.getBrowserCultureLang();

    }
  }

  updateUserBrowsingLanguage(locale: string): Observable<any> {

    this.setUserBrowsingLanguage(locale);

    var user = this.currentUser();
    if (user != null) {
      const urlUpdate = `${this._uri}/api/UserProfile/userBrowserLanguage`;
      return this.http.post<boolean>(urlUpdate, { id: user.userCode, langauge: locale });
    }
  }

  setUserBrowsingLanguage(locale: string) {
    if (locale != null && locale != '') {
      this.sessionStorage.set(Constants.USER_BROWSER_LANGUAGE, locale);
      this.useBrowsingLanguage();
    }
  }


  useBrowsingLanguage(): void {
    //var language = this.getUserBrowsingLanguage();
    //this.translate.use(language);
    this.subject.next({ event: UserService.LANGUAGE_CHANGE_EVENT });
  }

  updateShowMessageRequest(userModel: UserModel): Observable<any> {
    const urlUpdate = `${this._uri}${this.endpoint}/updateShowMessageRequest`;
    let result = this.http.post<boolean>(urlUpdate, userModel);
    return result;
  }
}
