import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanLoad, Route, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { AppConfigService } from '../../services/app-config/app-config.service'

@Injectable({
  providedIn: 'root'
})
export class LandingPageRedirectGuard implements CanActivate {

  constructor(private appConfigService: AppConfigService,private router: Router) {

  }

  /** 
   * Method to determine if we can activate the view based on custom logic.
   * @param {ActivatedRouteSnapshot} next
   * @param {RouterStateSnapshot} state
   * @returns {Observable<boolean> | Promise<boolean> | boolean}
   */
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {


    var redirectURL = "/dashboard";
    var config = this.appConfigService.get()
    if (config != null && config.urlMetaData != null && config.urlMetaData.defaultLandingPage != null && config.urlMetaData.defaultLandingPage != '') {
      redirectURL = config.urlMetaData.defaultLandingPage;
    }

    this.router.navigateByUrl(redirectURL);
    return true;

  }


}
