import { Component, OnInit, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from 'src/app/base/base.component';
import { BaseUiModel, State } from 'src/app/base/base-ui.model';


export enum LoaderType {
  COMPONENT = 1,
  PAGE = 2,
  SCREEN = 3,
  COMPONENT_ABSOLUTE = 3
}

export enum BackgroundType {
  OPAQUE = 1,
  TRANSPARENT = 2
}

class UiModel extends BaseUiModel {
  loaderType: LoaderType;
  backgroundType: BackgroundType;
  message: string;

  init(model: ContentLoaderModel) {
    this.state = model.state;
    this.loaderType = model.loaderType;
    this.backgroundType = model.backgroundType;
    this.message = model.message || 'Loading...';
  }

  get isTransparent(): boolean {
    return this.backgroundType === BackgroundType.TRANSPARENT;
  }

  get isComponent(): boolean {
    return this.loaderType === LoaderType.COMPONENT;
  }

  get isPage(): boolean {
    return this.loaderType === LoaderType.PAGE;
  }

  get isScreen(): boolean {
    return this.loaderType === LoaderType.SCREEN;
  }

  get styles(): object {
    return {
      transparent: this.isTransparent,
      page: this.isPage,
      screen: this.isScreen,
      component: this.isComponent
    };
  }

  get messageToShow(): string {
    return this.message;
  }

  get wrapperStyle(): object {
    return {
      relative: this.isComponent
     };
  }

}

export class ContentLoaderModel {
  constructor(public state: State,
              public loaderType: LoaderType = LoaderType.PAGE,
              public backgroundType: BackgroundType = BackgroundType.OPAQUE,
              public message: string = 'Loading...') {}
}

@Component({
  selector: 'content-loader',
  templateUrl: './content-loader.component.html',
  styleUrls: ['./content-loader.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContentLoaderComponent extends BaseComponent<UiModel> implements OnInit, OnChanges {

  @Input() model: ContentLoaderModel;

  constructor() {
    super();
  }

  ngOnInit() {
    this.uiModel.init(this.model);
  }

  initModel(): UiModel {
    return new UiModel();
  }

  ngOnChanges() {
    this.uiModel.state = this.model.state;
  }
}
