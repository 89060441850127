import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CategoryModel } from "src/app/models/categoryModel";

// tslint:disable-next-line:max-line-length
import { TenantModulesModel, TenantJobDesignationModel, TenantLocationTypeModel, TenantBussinessTypeModel, TenantLocale } from 'src/app/models/tenantModel';
import { AppConfigService } from '../../services/app-config/app-config.service'
import { Constants } from '../../shared/shared.constants';
import { Observable, of } from 'rxjs';
import { mergeMap, flatMap } from 'rxjs/operators';
import { tap } from 'rxjs/operators';
// tslint:disable-next-line:max-line-length
import { TenantConfigurationModel, TenantMetaDataModel, TenantPasswordConfigurationModel, TenantTagModel, TenantDashboardWidgetModel, TenantSecurityQuestionConfigurationModel } from '../../models/tenantModel';
import { BaseResultModel } from 'src/app/models/baseResultModel';
import { StorageService } from 'ngx-webstorage-service';
import { CORE_SESSION_STORAGE } from '../storage/storage.service';

@Injectable()
/**
 * Service class.
 */
export class TenantService {

  /**
   * Path uri.
   * @type {string}
   * @private
   */
  private _uri = '/api/tenant';

  /**
   * Url to endpoint api.
   * @type {string}
   */
  private endpoint = '';

  private _appConfigService: AppConfigService;

  /**
   * Endpoint request headers.
   * @type {HttpHeaders}
   */
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  /**
   * Component constructor and DI injection point.
   * @param {HttpClient} http
   */
  constructor(private http: HttpClient,
    @Optional() appConfigService: AppConfigService,
    @Inject(CORE_SESSION_STORAGE) private sessionStorage: StorageService) {
    this._appConfigService = appConfigService;
    if (this._appConfigService) {
      this.endpoint = `${this._appConfigService.get().tenantConfig.coreService.serviceUrl}`;
      this._uri = `${this._appConfigService.get().tenantConfig.coreService.serviceUrl}` + this._uri;
    }
  }

  /**
   * Pulls a list of modules
   * @returns {Observable<RoleModel[]>}
   */
  getTenantModules(): Observable<TenantModulesModel[]> {
    const endpoint = '/Modules';
    return this.http.get<TenantModulesModel[]>(`${this._uri}${endpoint}`);
  }

  /**
   * Pulls the tenant's password configuration
   * @returns {Observable<TenantPasswordConfigurationModel[]>}
   */
  getPasswordConfiguration(): Observable<TenantPasswordConfigurationModel> {
    const endpoint = '/PasswordConfig';
    return this.http.get<TenantPasswordConfigurationModel>(`${this._uri}${endpoint}`);
  }

  /**
   * Pulls the tenant's questions configuration
   * @returns {Observable<TenantSecurityQuestionConfigurationModel>}
   */
  getQuestionsConfiguration(): Observable<TenantSecurityQuestionConfigurationModel> {
    const endpoint = '/QuestionsConfig';
    return this.http.get<TenantSecurityQuestionConfigurationModel>(`${this._uri}${endpoint}`);
  }


  /**
   * Pulls a list of metadata
   * @returns {Observable<TenantMetaDataMode]>}
   */
  getTenantMetadata(): Observable<TenantMetaDataModel> {
    const persistedData = this.sessionStorage.get(Constants.TENANT_METADATA);
    if (persistedData !== undefined && persistedData != null && persistedData != '') {
      return of(<TenantMetaDataModel>JSON.parse(persistedData));
    } else {
      return this.http.get<any>(`${this._uri}`)
      .pipe(flatMap(userInfo => {
        if (userInfo != null) {
          this.sessionStorage.set(Constants.TENANT_METADATA, JSON.stringify(userInfo.metaData));
        }
        return of(userInfo.metaData);
      }));
    }
  }


  /**
 * Pulls a list of Roles objects.
 * @returns {Observable<RoleModel[]>}
 */
  getTenantJobDesignations(): Observable<TenantJobDesignationModel[]> {
    const persistedData = this.sessionStorage.get(Constants.TENANT_JOBDESIGNATION_KEY);
    if (persistedData !== undefined && persistedData != null && persistedData != '') {
      return of(<TenantJobDesignationModel[]>JSON.parse(persistedData));
    } else {
      const endpoint = '/JobDesignations';

      return this.http.get<TenantJobDesignationModel[]>(`${this._uri}${endpoint}`)
        .pipe(flatMap(locs => {
          if (locs != null) {
            this.sessionStorage.set(Constants.TENANT_JOBDESIGNATION_KEY, JSON.stringify(locs));
          }
          return of(locs);
        }));

      //return this.http.get<TenantJobDesignationModel[]>(`${this._uri}${endpoint}`);
    }

  }


  /**
 * Pulls a list of tenant location type objects.
 * @returns {Observable<TenantLocationTypeModel[]>}
 */
  getTenantTags(): Observable<TenantTagModel[]> {
    const endpoint = '/tenantTags';
    return this.http.get<TenantTagModel[]>(`${this._uri}${endpoint}`);
  }

  /**
  * Pulls the tenant url tag.
  * @returns {Observable<string>}
  */
  getTenantUrlTags(url: string): Observable<TenantTagModel[]> {
    const endpoint = '/tenantUrlTags';
    return this.http.get<TenantTagModel[]>(`${this._uri}${endpoint}?url=${url}`);
  }


  /**
  * Pulls a list of tenant location type objects.
  * @returns {Observable<TenantLocationTypeModel[]>}
  */
  getTenantLocationTypes(): Observable<TenantLocationTypeModel[]> {
    const endpoint = '/GetTenantLocationTypes';
    return this.http.get<TenantLocationTypeModel[]>(`${this._uri}${endpoint}`);
  }

  /**
  * Pulls a list of tenant location type objects.
  * @returns {Observable<TenantBussinessTypeModel[]>}
  */
  getTenantBussinessTypes(): Observable<TenantBussinessTypeModel[]> {
    const endpoint = '/GetAllBussinessTypes';
    return this.http.get<TenantBussinessTypeModel[]>(`${this._uri}${endpoint}`);
  }

  /**
  * Pulls a list of tenant location type objects.
  * @returns {Observable<TenantBussinessTypeModel[]>}
  */
  getTenantJobRolesByBussinessTypes(bussinessCode: string[]): Observable<TenantJobDesignationModel[]> {
    const qstring = [];
    // console.log(bussinessCode);
    bussinessCode.forEach(x => {
      qstring.push('bussinessTypeCodes=' + x)
    });
    const endpoint = '/JobRolesForBussinessType?' + qstring.join('&');
    // console.log(endpoint);
    return this.http.get<TenantJobDesignationModel[]>(`${this._uri}${endpoint}`);
  }

  /**
  * Pulls the tenant configuration object.
  * @returns {Observable<TenantConfigurationModel>}
  */
  getTenantConfiguration(): Observable<TenantConfigurationModel> {
    const uri = `${this._appConfigService.getResourceModuleApiUrl()}/api/resources`;
    const endpoint = '/TenantConfiguration';
    return this.http.get<TenantConfigurationModel>(`${uri}${endpoint}`);
  }


  /**
* Pulls a list of tenant locales.
* @returns {Observable<TenantLocale[]>}
*/
  getTenantLocales(): Observable<TenantLocale[]> {


    const persistedData = this.sessionStorage.get(Constants.TENANT_LOCALES);
    if (persistedData !== undefined && persistedData != null && persistedData != '') {
      return of(<TenantLocale[]>JSON.parse(persistedData));
    } else {
      const endpoint = '/Locales';
      return this.http.get<TenantLocale[]>(`${this._uri}${endpoint}`)
        .pipe(
          tap(t => {
            // grab the media..
            if (t != null) {
              this.sessionStorage.set(Constants.TENANT_LOCALES, JSON.stringify(t));
            }
          })
      );
    }
  }

  /**
* Pulls a list of tenant locales.
* @returns {Observable<TenantLocale[]>}
*/
  getTenantDashboardWidgets(): Observable<TenantDashboardWidgetModel[]> {

    const endpoint = '/dashboardWidgets';

    return this.http.get<TenantDashboardWidgetModel[]>(`${this._uri}${endpoint}`);
  }


  /**
 * Pulls a list of resource category objects.
 * returns {Observable<BaseResultModel<ResourceCategoryModel[]>}
 */
  getCategoriesFromDB(): Observable<BaseResultModel<CategoryModel[]>> {
    const endpoint = `${this.endpoint}/api/categories/parent`;
    return this.http.get<BaseResultModel<CategoryModel[]>>(`${endpoint}`);
  }


  getCategories(): Observable<CategoryModel[]> {
    const persistedData = this.sessionStorage.get(Constants.TENANT_CATEGORIES);
    if (persistedData !== undefined && persistedData != null && persistedData !== '') {
      return of(<CategoryModel[]>JSON.parse(persistedData));
    } else {
      return this.getCategoriesFromDB()
        .pipe(flatMap(result => {
          if (result.returnValue != null) {
            this.sessionStorage.set(Constants.TENANT_CATEGORIES, JSON.stringify(result.returnValue));
          }
          return of(result.returnValue);
        }));
    }
  }

}
