import { Component, OnInit, ViewChild, AfterContentInit, Inject } from '@angular/core';
import { PermissionsService } from '../../services/permissions/permissions.service';
import { AppConfigService } from '../../services/app-config/app-config.service'
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { HomePageSearchComponent } from './home-page-search/home-page-search.component';
import { TenantModuleResolutionService } from '../../services/tenant/tenant-navigation-resolution-service';
import { SynapzeToastrService } from '../../components/synapze-shareable-components/toastr/toastr.service';
import { HomePageSearchService } from '../../services/homepage/home-page-search.service';
import { SynapzeBlockUIService } from '../../components/synapze-shareable-components/block-ui/blockui.service';
import { SynapzeCxDialogService } from '../../components/synapze-shareable-components/synapze-cx-dialog/synapzedialog.service';
import { Constants } from '../../shared/shared.constants';
import { ConfirmationModalComponent } from '../../modules/resourceLibrary/views/user/components/confirmation-modal/confirmation-modal.component';
import { UserService } from 'src/app/services/user/user.service';
import { StorageService } from 'ngx-webstorage-service';
import { CORE_SESSION_STORAGE } from 'src/app/services/storage/storage.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [PermissionsService]
})
/**
 * Default home view. Fine to remove.
 */
export class HomeComponent implements OnInit,AfterContentInit {

  private navMenuItems= [
    {
        moduleCode: 'RESOURCELIBRARY',
        route: '/resources',
        displayName :"Resource Library",
        uiModuleCode:"resource-feed",
        useExternalRoute:false
    },
    {
        moduleCode: 'ASSESSMENT',
        route: 'assessment',
        displayName :"Test and Trivia",
        uiModuleCode:"test-and-trivia",
        useExternalRoute:false
    }
  ];


  private permissionsService: PermissionsService;

  isCollapsed = true;

  adminUrl: string;
  public navigationLinks: any[];

  showSearchPage: boolean = false;
  searchText: string;
  @ViewChild('searchComponent') searchComponent: HomePageSearchComponent;
  /**
   * Component constructor and DI injection point.
   */
  constructor(
    private tenantNavResolutionService: TenantModuleResolutionService,
    private permissionService: PermissionsService,
    private router: Router,
    private toastr: SynapzeToastrService,
    private readonly translate: TranslateService,
    private homepageSearchService: HomePageSearchService,
    private appConfigService: AppConfigService,
    private synapzeBlockUIService: SynapzeBlockUIService,
    private dialogService: SynapzeCxDialogService,
    private translateService: TranslateService,
    private userService: UserService,
    @Inject(CORE_SESSION_STORAGE) private sessionStorage: StorageService
  ) {
    this.permissionsService = permissionService;
    let adminModule = appConfigService.getModule(appConfigService.get()
      .tenantConfig.userAdminModuleCode);
    this.adminUrl = adminModule != null ? adminModule.serviceUrl : "";
    this.navigationLinks = this.tenantNavResolutionService.getEnabledModules(this.navMenuItems);

    const browserLang = userService.getUserBrowsingLanguage();
    translate.use(browserLang);
    userService.events().subscribe(message => {
      if (message.event == UserService.LANGUAGE_CHANGE_EVENT) {
        const browserLang = userService.getUserBrowsingLanguage();
        translate.use(browserLang);
      }
    });


    this.router.events.subscribe((event) => {
      if (this.showSearchPage) {
        this.searchComponent.close();
      }
    })

  
    //if (this.router.url == "" || this.router.url == '/') {
    //  var redirectURL = "/dashboard";  
    //  var config = this.appConfigService.get()
    //  if (config != null && config.urlMetaData != null && config.urlMetaData.defaultLandingPage != null && config.urlMetaData.defaultLandingPage != '') {
    //    redirectURL = config.urlMetaData.defaultLandingPage;
    //  }

    //  this.router.navigate([redirectURL]);
    //}
  }
  


  /**
   * Called part of the component lifecycle. Best first
   * place to start adding your code.
   */
  ngOnInit() {
  
  }


  hasAccess(moduleName: string) {
    return true;
    //var modulePermissions: string[] = [];

    //switch (moduleName.toLowerCase()) {
    //  case 'admin': {
    //    modulePermissions = modulePermissions.concat(ModulePermissions.Admin);
    //  }
    //  default:
    //}

    //return modulePermissions.some((value):boolean => {
    //  return this.permissionsService.checkUserPermission(value);
    //});
  }

  displaySearchResults(searchText: string) {
    this.searchText = searchText;
    this.showSearchPage = !this.showSearchPage;
    this.searchComponent.searchModule(null, searchText);
  }

  onSearchClosed(event) {
    this.searchText = null;
    this.showSearchPage = false;
  }

  ngAfterContentInit() {
    const categoriesUpdated = this.sessionStorage.get(Constants.CATEGORIES_UPDATED);
    if (categoriesUpdated) {
      this.showPreferencesUpdatedMessage();
      sessionStorage.removeItem(Constants.CATEGORIES_UPDATED);
    }
  }
  private showPreferencesUpdatedMessage(): void {
    setTimeout(() => this.dialogService.show(ConfirmationModalComponent,false));
  }


}
