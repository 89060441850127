export class Constants {
  static readonly PERMISSIONS_STORAGE_KEY = 'permissions';
  static readonly TENANT_JOBDESIGNATION_KEY = 'tenantJobDesignation';
  static readonly TENANT_LOCATIONS = 'tenantLocations';
  static readonly TENANT_LOCALES = 'tenantLocales';
  static readonly TENANT_ROLES = 'tenantRoles';
  static readonly TENANT_GROUPS = 'tenantGroups';
  static readonly TENANT_CATEGORIES = 'tenantCategories';
  static readonly TENANT_METADATA = 'tenantMetadata';
  static readonly TENANT_CATEGORIES_CONFIGURATION = 'tenantCategoriesConfiguration';
  static readonly USER_CATEGORIES = 'userCategories';
  static readonly USER_INFO = 'userInfo';
  static readonly PARENT_CATEGORIES = 'parentCategories';
  static readonly CATEGORIES_UPDATED = 'categoriesUpdated';
  static readonly ASSET_PENDING_PUBLISHING = 'PENDING_PUBLISHING';
  static readonly ASSET_PUBLISHED = 'PUBLISHED';
  static readonly STATUS_PUBLISHED = 'PUBLISHED';
  static readonly STATUS_UNPUBLISHED = 'UNPUBLISHED';
  static readonly CURRENT_USER = 'CURRENT_USER';
  static readonly WELCOME_SKIPPED = 'WELCOME_SKIPPED';
  static readonly USER_BROWSER_LANGUAGE = 'USER_BROWSER_LANGUAGE';
  static readonly LOGOUT_IN_PROGRESS = 'LOGOUT_IN_PROGRESS';
  static readonly LOGIN_IN_PROGRESS = 'LOGIN_IN_PROGRESS';
  static readonly PRIVACY_UP_TO_DATE = 'PRIVACY_UP_TO_DATE';
  static readonly TERMS_UP_TO_DATE = 'TERMS_UP_TO_DATE';
  static readonly PRIVACY_POLICY_TYPE = 'PRIVACY_POLICY';
  static readonly LEARNING_PATH_WELCOME = 'LEARNING_PATH_WELCOME';
  static readonly TERMS_CONDITIONS_TYPE = 'TERMS_CONDITIONS';
  static readonly TERMS_CONDITIONS_TAGCODE_FORD_EN = 'TC_TAGCODE_FORD_EN';
  static readonly TERMS_CONDITIONS_TAGCODE_FORD_FR = 'TC_TAGCODE_FORD_FR';
  static readonly TERMS_CONDITIONS_TAGCODE_LINCOLN_EN = 'TC_TAGCODE_LINCOLN_EN';
  static readonly TERMS_CONDITIONS_TAGCODE_LINCOLN_FR = 'TC_TAGCODE_LINCOLN_FR';
}



export class AssetTypeCodes {
  static readonly DOCUMENT = 'DOCUMENT';
  static readonly URL_WEBINAR = 'URL_WEBINAR';
  static readonly VIDEO_MP4 = 'VIDEO_MP4';
  static readonly URL_PODCAST = 'URL_PODCAST';
  static readonly COURSE_HOSTED = 'COURSE_HOSTED';
  static readonly IMAGE = 'IMAGE';
  static readonly POWERPOINT = 'POWERPOINT';
}
