import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanLoad, Route, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { PermissionsService } from '../services/permissions/permissions.service';
import { ModulePermissions } from '../models/appModules.permissions';
import { SynapzeCxOAuthserviceService } from './synapze-cx-oauthservice.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad {
  private authService: SynapzeCxOAuthserviceService
  private permissions: PermissionsService;

  constructor(private oauthService: SynapzeCxOAuthserviceService, private permissionsService: PermissionsService, private router: Router) {
    this.authService = oauthService;
    this.permissions = permissionsService;
    //console.log("AUTHGUARD");
  }

  /** 
   * Method to determine if we can activate the view based on custom logic.
   * @param {ActivatedRouteSnapshot} next
   * @param {RouterStateSnapshot} state
   * @returns {Observable<boolean> | Promise<boolean> | boolean}
   */
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {



    var modulePermissions: string[] = [];
    modulePermissions = modulePermissions.concat(ModulePermissions.Admin);
    if (this.oauthService.HasValidToken()) {
      return true;
    }
    else {
      if (!this.oauthService.IsloginInProgress() && !this.oauthService.IsloginOutProgress()) {
        this.oauthService.Login(document.location.href);
      }
      return false;
    }
  }


  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {



    var modulePermissions: string[] = [];
    modulePermissions = modulePermissions.concat(ModulePermissions.Admin);
    if (this.oauthService.HasValidToken()) {
      return true;
    }
    else {
      if (!this.oauthService.IsloginInProgress() && !this.oauthService.IsloginOutProgress()) {
        this.oauthService.Login(document.location.href);
      }
      return false;
    }
  }
}
