import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';


import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CustomHttpInterceptorService } from "./services/custom-http-interceptor.service";
import { PermissionsService } from './services/permissions/permissions.service';
import { TenantService } from './services/tenant/tenant.service';
import { CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';
import { CoreModule } from './core/core.module';
import { TranslateModule, TranslateLoader, TranslatePipe, TranslateService, TranslateParser } from '@ngx-translate/core';
import { LocalizationLoader, CustomTranslateParser } from "./services/localization-loader.service";
//import { BsDropdownModule } from 'ngx-bootstrap/dropdown'
import { CollapseModule, AlertModule } from 'ngx-bootstrap';
import { OAuthModule, OAuthStorage, OAuthService } from 'angular-oauth2-oidc';
import { AppConfigService } from './services/app-config/app-config.service';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { DefaultLayoutComponent } from './containers';
import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { NgxGaugeModule } from 'ngx-gauge-fix';
import { DeviceDetectorModule } from 'ngx-device-detector';

import { SynapzeBlockUIService } from 'src/app/components/synapze-shareable-components/block-ui/blockui.service';
import { Angulartics2Module } from 'angulartics2';
import { FileSaverModule, FileSaverService } from 'ngx-filesaver';


import {
  ToastrModule,
  ToastNoAnimation,
  ToastNoAnimationModule
} from 'ngx-toastr';
import { UserService } from './services/user/user.service';
import { LocalizationService } from './services/localization/localization.service';
import { TagManagerService } from 'src/app/services/tagManager/tagManager.service';



import { initializeApp } from './app.initializer';
import { PreferencesModule } from './modules/resourceLibrary/views/user/preferences/preferences.module';
import { PoliciesModule } from './modules/resourceLibrary/views/user/policy/policies.module';
import { TenantNotificationService } from './modules/resourceLibrary/views/user/services/tenant-notification.service';
import { CORE_SESSION_STORAGE, OAuthStorageService } from './services/storage/storage.service';


export function HttpLoaderFactory(http: HttpClient, appConfigService: AppConfigService) {
  //return new TranslateHttpLoader(http, "/public/lang-files/", "-lang.json");
  return new LocalizationLoader(http, "Common", "Core", appConfigService);
}



@NgModule({
  declarations: [
    AppComponent,
    P404Component,
    P500Component,
    DefaultLayoutComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule ,
    AppRoutingModule,
    HttpClientModule,
    AlertModule.forRoot(),
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: ['https://c08dc89a-c8de-414d-bdf9-b85fb2958be0.mock.pstmn.io/api/'],
        sendAccessToken: true
      }
    }),
    CoreModule.forRoot(),
    CollapseModule.forRoot(),
    //BsDropdownModule.forRoot(),
    ToastNoAnimationModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      progressBar: true
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient, AppConfigService]
      },
      parser: { provide: TranslateParser, useClass: CustomTranslateParser },
    }),
    SweetAlert2Module.forRoot({
      buttonsStyling: false,
      customClass: 'modal-content',
      confirmButtonClass: 'btn btn-primary',
      cancelButtonClass: 'btn'
    }),
    NgxGaugeModule,
    PreferencesModule,
    //PoliciesModule,
    Angulartics2Module.forRoot(),
    DeviceDetectorModule.forRoot(),
      FileSaverModule

  ],
  providers: [
    AppConfigService,
    PermissionsService,
    TranslateService,
    { provide: HTTP_INTERCEPTORS, useClass: CustomHttpInterceptorService, multi: true },
    { provide: OAuthStorage, useClass: OAuthStorageService },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfigService, PermissionsService, OAuthService, TranslateService, CORE_SESSION_STORAGE],
      multi: true
    },
    TenantService,
    UserService,
    TenantNotificationService,
    LocalizationService,
    SynapzeBlockUIService,
    TagManagerService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})

/**
 * Main app module. Import your submodules here.
 */
export class AppModule {
}
