<content-loader [model]="contentLoadingModel">
  <form [formGroup]="homepageSearchForm">
    <div>
      <div class="search-page-header">
        <div class="container">
          <div class="col-xs-12 col-md-6">

            <div class="search-area">
              <button type="submit" class="search-link" (click)="onSearchSubmit()" [disabled]="homepageSearchForm.invalid"><div>&#x2315;</div></button>
              <input id="homepageSearchInput" type="text" placeholder="{{'core-homepage-search_searchplaceholder' | translate : '{ defaultText: \'Tap text to search\'}'}}"
                     name="homepageSearch" formControlName="searchtext">
              <!-- (keyup.enter)="onSearchSubmit('3')" -->
              <button class="btn-link clear-link" style="color:inherit!important" (click)="onClearSearchInput()">{{'core-homepage-search_searchplaceholder_clear' | translate : '{ defaultText: \'clear\'}'}}</button>

              <br />

              <div *ngIf="homepageSearchForm.controls.searchtext.invalid && (homepageSearchForm.controls.searchtext.dirty)"
                   class="alert alert-danger">
                {{ 'core-homepagesearch-search_field_length' | translate : '{ defaultText : \'Search text character length must be at least \' }' }} {{minCharacterLength}}
              </div>

            </div>

          </div>
          <div class="col-xs-12 col-md-6">
            <p-tabMenu [model]="tabItems" [activeItem]="activeTabItem" class="search-tab-list"></p-tabMenu>
          </div>
        </div>
      </div>
    </div>

    <div>

      <div class="container filters" [hidden]="activeTabItem?.id != 'RESOURCELIBRARY'">
        <div class="row justify-content-md-center">
          <div class="col-md-10 col-md-offset-1">
            <div class="filter-types">
              <mat-button-toggle-group name="fontStyle" aria-label="Font Style" formControlName="assetTypeCode" (change)="onAssetTypeChanged()" multiple>
                <mat-button-toggle value="VIDEO_MP4">
                  <img src="../../../../assets/bond-icons/search-icons/icn-media-video.png" alt="video" class="inactive" />
                  <img src="../../../../assets/bond-icons/search-icons/icn-media-video-prp.png" alt="video" class="active" />
                </mat-button-toggle>
                <mat-button-toggle value="URL_PODCAST">
                  <img src="../../../../assets/bond-icons/search-icons/icn-media-podcast_new.png" alt="podcast" class="inactive" />
                  <img src="../../../../assets/bond-icons/search-icons/icn-media-podcast-prp.png" alt="podcast" class="active" />
                </mat-button-toggle>
                <mat-button-toggle value="URL_WEBINAR">
                  <img src="../../../../assets/bond-icons/search-icons/icn-media-webinar_new.png" alt="webinars" class="inactive" />
                  <img src="../../../../assets/bond-icons/search-icons/icn-media-webinar-prp.png" alt="webinars" class="active" />
                </mat-button-toggle>
                <mat-button-toggle value="DOCUMENT">
                  <img src="../../../../assets/bond-icons/search-icons/icn-media-document.png" alt="PDF" class="inactive" />
                  <img src="../../../../assets/bond-icons/search-icons/icn-media-document-prp.png" alt="PDF" class="active" />
                </mat-button-toggle>
                <mat-button-toggle value="COURSE_HOSTED">
                    <img src="../../../assets/bond-icons/search-icons/icn-media-learning.png" alt="learning" class="inactive" />
                    <img src="../../../assets/bond-icons/search-icons/icn-media-learning-prp.png"  alt="learning" class="active" />
                </mat-button-toggle>
                <mat-button-toggle value="POWERPOINT">
                    <img src="../../../assets/bond-icons/search-icons/icn-media-data.png" alt="data" class="inactive" />
                    <img src="../../../assets/bond-icons/search-icons/icn-media-data-prp.png"  alt="data" class="active" />
                </mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="!isLoading && isContainerEmpty" class="alert alert-warning">
        <strong *ngIf="searchText!=null && searchText!=undefined">{{'core-homepage-search_noitemsfound_message' | translate : '{ defaultText: \'No items were found. Please refine your query and try again\'}'}}.</strong>
        <strong *ngIf="searchText==null || searchText==undefined">{{'core-homepage-search_textcleared_message' | translate : '{ defaultText: \'Please enter a search term\'}'}}.</strong>
      </div>

      <div class="container" #searchwidgets>

      </div>
    </div>
    <!--<div class="row" [hidden]="activeTabItem?.id != 'RESOURCELIBRARY'">



      <div class="container-full search-container" *ngIf="activeTabItem?.id == 'RESOURCELIBRARY' && list?.length > 0">

        <div class="resource-container container p-b-30 search-results-list clearfix">
          <app-result-list-item [list]="list" [viewMode]="'list'" [showToolbar]="false"></app-result-list-item>
        </div>
      </div>
    </div>-->

  </form>
</content-loader>
