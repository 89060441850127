import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HomeModule } from '../views/home/home.module';
import { PageNotFoundModule } from '../views/page-not-found/page-not-found.module';
import { AppConfigService } from '../services/app-config/app-config.service';
import { LogoutModule } from '../views/logout/logout.module';
import { CORE_LOCAL_STORAGE, CORE_SESSION_STORAGE } from '../services/storage/storage.service';
import { LOCAL_STORAGE, SESSION_STORAGE } from 'ngx-webstorage-service';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		HomeModule,
		PageNotFoundModule,
		LogoutModule
	],
	declarations: [],
	exports: [],
	providers: [
		AppConfigService,
		{ provide: CORE_LOCAL_STORAGE, useExisting: LOCAL_STORAGE },
		{ provide: CORE_SESSION_STORAGE, useExisting: SESSION_STORAGE },
	]
})

/**
 * Main/singular application module for the application. Good
 * place to add in services and application specific views/models/etc.
 *
 * @see https://angular.io/guide/ngmodule#the-core-module.
 */
export class CoreModule {

	constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
		if (parentModule) {
			throw new Error('CoreModule is already loaded. Import it in the AppModule only');
		}
	}

	static forRoot(): ModuleWithProviders {
		return {
			ngModule: CoreModule,
			providers: [
				// add in service config providers
				// {provide: MyServiceConfig, useValue: config}
			]
		};
	}
}
