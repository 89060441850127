  <div class="modal-dialog sign-out-modal" role="document">
  <div class="modal-content">    
    <div class="modal-body">
        <h3> {{'core-logout-signedOutLabel' | translate : '{ defaultText :  "You have been signed out" }'}}</h3>       
    </div>
    <div class="modal-footer center">
        <button _ngcontent-c2="" aria-controls="collapseBasic" class="btn btn-primary-action" type="button"
        aria-expanded="true" (click)="login()">  {{'core-logout-loginButton' | translate : '{ defaultText :  "Login" }'}} </button>
    </div>
</div>
</div>
