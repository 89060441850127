import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-category-control',
  templateUrl: './category-control.component.html',
  styleUrls: ['./category-control.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CategoryControlComponent),
      multi: true
    }
  ]
})
export class CategoryControlComponent implements ControlValueAccessor {

  checked: boolean;
  @Input() categoryName: string;
  _onChange: (value: any) => void;
  _onTouched: (value: any) => void;

  constructor() { }

  toggle() {
    this.checked = !this.checked;
    this._onChange(this.checked);
  }

  writeValue(value: boolean) {
    this.checked = !!value;
  }

  registerOnChange(fn: (value: any) => void) {
    this._onChange = fn;
  }

  registerOnTouched(fn: any) {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
  }



}
