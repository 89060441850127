<div class="footer-section">
  <div class="container">
    <div class="row footer-top">
      <div class="col-xs-6 col-sm-4">


        <div class="footer-logo" *ngIf="tenantFooterConfiguration?.showLogo == true && (tenantUrls == null || tenantUrls.length <= 1) ">
          <div class="footer-logo-img"></div>
        </div>
        <div class="footer-tenant-selector" *ngIf="tenantUrls !=null && tenantUrls.length > 1">
          <p-menu [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'" #menu [popup]="true" [model]="tenantList">
          </p-menu>
          <button type="button" pButton class="tenantButton" (click)="menu.toggle($event)"><div class="arrow-down-footer"></div></button>
        </div>
      </div>
      <div class="col-xs-12 col-sm-4 footer-social">
        <a *ngFor="let tenantInfo of tenantFooterConfiguration?.tenantFooterSocialMediaConfigurations" href="//{{tenantInfo.mediaUrl}}" class="footer-social-icon {{tenantInfo.mediaName}}"></a>
      </div>
        <div class="col-xs-6 col-sm-4">
          <div class="footer-select">

            <!--<select name="location" id="location" [(ngModel)]="currentLanguage" (change)="change()">
    <option [value]="local.locale" *ngFor="let local of tenantLocales">
      {{local.localeName}}
    </option>
  </select>-->
            <synapze-cx-simple-dropdown *ngIf="tenantFooterConfiguration?.showLanguageSwitchingOption == true"
                                        class="footer-lang"
                                        [enableMultiSelection]="false"
                                        [readOnly]="false"
                                        (onSelectionChanged)="change($event)"
                                        [(dropdownitems)]="languages"
                                        [(ngModel)]="currentLanguage">
            </synapze-cx-simple-dropdown>

            <!--<ng-container  class="m-t-10" *ngIf="tenantUrls !=null && tenantUrls.length > 1">
              <synapze-cx-simple-dropdown class="footer-lang"
                                          [enableMultiSelection]="false"
                                          [readOnly]="false"
                                          (onSelectionChanged)="changeUrl($event)"
                                          [(dropdownitems)]="tenantUrls"
                                          [(ngModel)]="currentUrl">
              </synapze-cx-simple-dropdown>
            </ng-container>-->

              <!--<select name="location" id="location" [(ngModel)]="currentLanguage" (change)="change()">
    <option value="en-US">
      English
    </option>
    <option value="fr-CA">
      French
    </option>
  </select>-->
</div>
      </div>
    </div>
    <!--<div class="footer-line"></div>
  <div class="row footer-menu-1">
    <div class="col-xs-12">
      <div class="footer-col">
        <div class="footer-col-header"><h3>Heading</h3></div>
        <ul>
          <li><a href="#">Lorem ipsum</a></li>
          <li><a href="#">dolor sit amet</a></li>
          <li><a href="#">consectetur dolor sit amet maecenas</a></li>
          <li><a href="#">Lorem ipsum</a></li>
          <li><a href="#">Lorem ipsum</a></li>
        </ul>
      </div>
      <div class="footer-col">
        <div class="footer-col-header"><h3>Heading</h3></div>
        <ul>
          <li><a href="#">Lorem ipsum</a></li>
          <li><a href="#">dolor sit amet</a></li>
        </ul>
      </div>
      <div class="footer-col">
        <div class="footer-col-header"><h3>Heading</h3></div>
        <ul>
          <li><a href="#">Lorem ipsum</a></li>
          <li><a href="#">dolor sit amet</a></li>
          <li><a href="#">consectetur</a></li>
        </ul>
      </div>
      <div class="footer-col">
        <div class="footer-col-header"><h3>Heading</h3></div>
        <ul>
          <li><a href="#">Lorem ipsum</a></li>
          <li><a href="#">dolor sit amet</a></li>
          <li><a href="#">consectetur</a></li>
          <li><a href="#">adipiscing elit</a></li>
        </ul>
      </div>
      <div class="footer-col">
        <div class="footer-col-header"><h3>Heading</h3></div>
        <ul>
          <li><a href="#">Lorem ipsum</a></li>
          <li><a href="#">dolor sit amet</a></li>
          <li><a href="#">consectetur</a></li>
        </ul>
      </div>
    </div>
  </div>-->

    <div class="row">
      <div class="col-xs-12"><div class="footer-line"></div></div>
      <div class="col-xs-6 mobile-100">
        <div class="footer-menu-2">
          <ul>
            <li *ngIf="tenantFooterConfiguration?.termsAndConditions == true"><a id='TermsAndConditions' href='javascript: void(0)' (click)="showTermsConditionsModal()"> {{'core-common-policies_termsconditionslabel' | translate : '{ defaultText :  "Terms and Conditions" }'}}</a></li>
            <li *ngIf="tenantFooterConfiguration?.privacy == true" class="privacy-footer"><a id='PrivacyPolicy' href='javascript: void(0)' (click)="showPoliciesModal()">{{'core-common-policies_privacypolicylabel' | translate : '{ defaultText :  "Privacy Policy" }'}}</a></li>
            <li *ngFor="let tenantInfo of tenantFooterConfiguration?.additionalLinks"><a target="_blank" href="https://{{tenantInfo.linkUrl}}">{{tenantInfo.linkName}}</a></li>
            <!--<li *ngFor="let tenantInfo of tenantFooterConfiguration?.additionalLinks"><a href="https://www.fmcdealer.dealerconnection.com/sites/ford_lm/sales/Pages/default.aspx" target="_blank">InFord</a></li>-->
          </ul>
        </div>
      </div>
      <div class="col-xs-6 mobile-100">
        <div class="footer-menu-3">
          
          <a *ngIf="tenantFooterConfiguration?.submitAnIssue == true" href="{{'core-common-submit_an_issue_email' | translate}}" class="footer-contact-icon submit">{{'core-common-email' | translate : '{ defaultText :  "p2p@bondbrandloyalty.com" }'}}</a>
          <a *ngIf="tenantFooterConfiguration?.contactNumber" class="footer-contact-icon tel" href="tel:{{tenantFooterConfiguration.contactNumber}}">{{tenantFooterConfiguration.contactNumber}}</a>
          <!--<a *ngIf="tenantFooterConfiguration?.contactNumber" class="footer-contact-icon tel">1-800-263-0250</a>-->
        </div>
    </div>
  </div>

</div>
  </div>
<div class="footer-bottom">
  <div class="container">
    <div class="row">
      <div class="col-xs-12 col-md-6 footer-copy-rights">{{'core-common-copyright_label' | translate : '{ defaultText :  "Pevaar Software Factory © 2019 All rights reserved." }'}}</div>
    </div>
  </div>
</div>
<synapze-cx-modal #policiesModal modalClass="modal-lg">

  <div header>
    <div class="row">
      <div class="col-xs-12 new-assessment-header">
        <h3> {{'core-common-policies_privacypolicylabel' | translate : '{ defaultText :  "Privacy Policy" }'}}</h3>
      </div>
    </div>
  </div>
  <div body>
    <div class="custom-html" [innerHTML]="privacyNotification?.htmlContent"></div>
    <div>
      <span class="alert alert-warning d-flex justify-content-center col-xs-12" role="alert">
        {{'core-common-policies_versionlabel' | translate : '{ defaultText :  "Version" }'}}
        {{privacyNotification?.version  }} &#8226;
        {{  'core-common-policies_updatedlabel' | translate : '{ defaultText :  "Updated" }'}}
        {{privacyNotification?.effectiveFrom  | date :'longDate'}}
      </span>
    </div>

    <button _ngcontent-c2="" aria-controls="collapseBasic" class="btn btn-primary" type="button"
            aria-expanded="true" (click)="closePoliciesModal()">
      {{'core-common-policies_donebutton' | translate : '{ defaultText :  "Done" }'}}
    </button>
  </div>

</synapze-cx-modal>


<synapze-cx-modal #termsConditionsModal modalClass="modal-lg">

  <div header>
    <div class="row">
      <div class="col-xs-12 new-assessment-header">
        <h3>{{'core-common-policies_termsconditionslabel' | translate : '{ defaultText :  "Terms and Conditions" }'}}</h3>
      </div>
    </div>
  </div>
  <div body>
    <div class="custom-html" [innerHTML]="termsNotification?.htmlContent"></div>
    <div>
      <span class="alert alert-warning d-flex justify-content-center col-xs-12 m-t-20" role="alert">
        {{'core-common-policies_versionlabel' | translate : '{ defaultText :  "Version" }'}}
        {{termsNotification?.version  }} &#8226;
        {{  'core-common-policies_updatedlabel' | translate : '{ defaultText :  "Updated" }'}}
        {{termsNotification?.effectiveFrom  | date :'longDate'}}
      </span>
    </div>

    <button _ngcontent-c2="" aria-controls="collapseBasic" class="btn btn-primary" type="button"
            aria-expanded="true" (click)="closeTermsModal()">
      {{'core-common-policies_donebutton' | translate : '{ defaultText :  "Done" }'}}
    </button>
  </div>

</synapze-cx-modal>
