<form [formGroup]="dropDownForm">

  <ng-container *ngIf="showLabel==true; else elseTemplate">
    <label for="dropdown" [style.display]="inline">{{label}}</label>
  </ng-container>
  <ng-template #elseTemplate>
    <label for="dropdown" [style.display]="none">{{label}}</label>
  </ng-template>
  <ng-container *ngIf="enableMultiSelection==true; else noMultiSelection">
    <ng-select labelForId="dropdown"
               formControlName="dropdownControl"
               [items]="_dropdownitems"
               [multiple]="true"
               [closeOnSelect]="false"
               bindLabel="label"
               bindValue="value"
               placeholder="{{_placeholder}}"
               (change)="onChange()"
               title="{{_title}}"
               [clearable]="true"
               [markFirst]=false>
      <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
        <div class="checkbox-container-list">
          <label for="item-{{index}}" [style.display]="'none'">{{item.label}}</label>
          <input id="item-{{index}}" class="custom-checkbox-list" type="checkbox" [ngModelOptions]="{standalone: true}" [ngModel]="item$.selected" /> {{item.label}}
          <span class="checkmark-list"></span>
        </div>       
      </ng-template>
      <ng-template *ngIf="onlyShowTotalNoOfItemsSelected" ng-multi-label-tmp let-items="items" let-clear="clear">
        {{'core-common-dropdown_items_selected' | translate : '{ itemsLength: ' + items.length + ', defaultText: "\{\{itemsLength\}\} items selected"}'}}
      </ng-template>
      <ng-template *ngIf="!onlyShowTotalNoOfItemsSelected" ng-multi-label-tmp let-items="items" let-clear="clear">        
          
      </ng-template>
    </ng-select>
  </ng-container>
  <ng-template #noMultiSelection>
    <ng-select labelForId="dropdown"
               formControlName="dropdownControl"
               [items]="_dropdownitems"
               [multiple]="false"
               [clearable] = "true"
               [closeOnSelect]="true"
               bindLabel="label"
               bindValue="value"
               placeholder="{{_placeholder}}"
               (change)="onChange()"
               title="{{_title}}"
               [markFirst]=false>
    </ng-select>
  </ng-template>

  
</form>
