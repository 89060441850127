import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from 'src/app/vos/app-config/app-config';
import { Constants } from 'src/app/shared/shared.constants';
import { Observable, Subject } from 'rxjs';
import { AppConfigService } from 'src/app/services/app-config/app-config.service';
import { TenantNotificationModel } from '../../../models/tenantNotificationModel';
import { UserNotificationAceptanceInfoModel } from '../../../models/userNotificationAceptanceInfoModel';
import { UserService } from 'src/app/services/user/user.service';
import { UserModel } from 'src/app/models/UserModel';


@Injectable()
/**
 * Service class.
 */
export class TenantNotificationService {

   /**
   * Path uri.
   * @type {string}
   * @private
   */
  private _coreUri = '';
  private _identityUri = '';

  /**
   * Url to endpoint api.
   * @type {string}
   */
  private endpoint = '/api/TenantNotification';
  private identityEndpoint = '/api/UserNotificationAcceptance';
  private config: AppConfig;
  private user: UserModel;
  public tenantModel: TenantNotificationModel = null;

  /**
   * Component constructor and DI injection point.
   * @param {HttpClient} http
   * @param {AppConfigService} config
   */
  constructor(private http: HttpClient, configService: AppConfigService, userService: UserService) {
    if (configService) {
      this.config = configService.get();
      if (this.config != null)
      this._coreUri = this.config.tenantConfig.coreService.serviceUrl;
      this._identityUri = this.config.tenantConfig.authService.serviceUrl;
    }
    userService.getUserInfo().subscribe(result=>this.user = result)

  }


  getTenantNotification(type: string, locale: string): Observable<TenantNotificationModel> {
    var tenatCode = this.config.tenantConfig.tenantCode;
    const url = `${this._coreUri}${this.endpoint}/GetNotificationByTypeWithDate?tenantCode=${tenatCode}&type=${type}&locale=${locale}`;
    return this.http.get<TenantNotificationModel>(url);
  }

  getTenantNotificationByType(type: string, locale: string): Observable<TenantNotificationModel> {
    const tenatCode = this.config.tenantConfig.tenantCode;
    const url = `${this._coreUri}${this.endpoint}/GetNotificationByType?tenantCode=${tenatCode}&type=${type}&locale=${locale}`;
    return this.http.get<TenantNotificationModel>(url);
  }

  getLastNotification(type: string, locale:string): Observable<TenantNotificationModel> {
    var tenatCode = this.config.tenantConfig.tenantCode;
    const url = `${this._coreUri}${this.endpoint}/GetLastNotification?tenantCode=${tenatCode}&type=${type}&locale=${locale}`;
    return this.http.get<TenantNotificationModel>(url);
  }

  acceptPrivacyAndTerms(notificationCode: string): Observable<any> {
    var model = new UserNotificationAceptanceInfoModel();
    model.notificationCode = notificationCode;
    model.tenantCode = this.config.tenantConfig.tenantCode;
    // console.log(JSON.stringify(this.user));
    model.userCode = this.user.userCode;
    const url = `${this._identityUri}${this.identityEndpoint}`;
    return this.http.post<any>(url,model);
  }

}
