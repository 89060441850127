import { Inject, Directive, Component, ContentChild, Input, TemplateRef, ViewEncapsulation, EventEmitter, Output, ViewChildren, ViewChild, AfterViewInit, ComponentFactoryResolver, ViewContainerRef, OnDestroy } from '@angular/core';
import { BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ModalModule } from 'ngx-bootstrap/modal';

import { Template } from '@angular/compiler/src/render3/r3_ast';
import { SwalPartialTargets, SwalComponent } from '@toverux/ngx-sweetalert2';


@Directive({
  selector: '[app-component-container]',
})

export class AppComponentContainer implements OnDestroy {
  vc: ViewContainerRef;
  shared: any;

  ngOnDestroy(): void {
    this.shared.destroyContainer(this.vc);
  }
  constructor(vc: ViewContainerRef, @Inject('app-component-service') shared) {
    this.vc = vc;
    this.shared = shared;
    shared.registerContainer(vc);
  }
}

const shared = {
  createListeners: [],
  destroyListeners: [],
  onContainerCreated(fn) {
    this.createListeners.push(fn);
  },
  onContainerDestroyed(fn) {
    this.destroyListeners.push(fn);
  },
  registerContainer(container) {
    this.createListeners.forEach((fn) => {
      fn(container);
    })
  },
  destroyContainer(container) {
    this.destroyListeners.forEach((fn) => {
      fn(container);
    })
  },
  clearContainers() {
    this.createListeners = [];
    this.destroyListeners = [];
  }
};



@Component({
  selector: 'synapze-cx-modal',
  providers: [
    {
      provide: 'app-component-service',
      useValue: shared
    }
  ],
  templateUrl: './synapze-cx-modal.component.html'
})
export class SynapzeCxModalComponent {

  modalRef: BsModalRef;
  
  @ViewChild("template") template: TemplateRef<any>;
  @Input() modalClass: string = "modal-lg modal-info";
  @Input() fancy: boolean = false;
  @Input() showCloseIcon: boolean = true;
  @ViewChild('swalComponent') private swalComponent: SwalComponent;
  
  vc: ViewContainerRef;
  shared: any;
  //constructor(private modalService: BsModalService,
  //  public readonly swalTargets: SwalPartialTargets,
  //  private cfr: ComponentFactoryResolver) {
  //}

  constructor(private modalService: BsModalService, public readonly swalTargets: SwalPartialTargets,
    private cfr: ComponentFactoryResolver, @Inject('app-component-service') shared) {
    this.shared = shared;
  }

  ngAfterViewInit() {
  }

  public show(): void {
    this.shared.clearContainers();
    if (!this.fancy) {
      this.modalRef = this.modalService.show(this.template, { class: `${this.modalClass}`, backdrop: 'static' });
    }
    else {
      this.swalComponent.show();
    }

  }

  public showComponents(component: any, onComponentCreated: (ref: any) => void): any {
    this.shared.clearContainers();
    this.shared.onContainerCreated((container) => {
      this.vc = container;
      var comp = this.cfr.resolveComponentFactory(component);
      const ref = this.vc.createComponent(comp);
      ref.changeDetectorRef.detectChanges();
      if (onComponentCreated != null)
        onComponentCreated(ref);
    });

    this.shared.onContainerDestroyed(() => {
      this.vc = undefined;
    })

    if (!this.fancy) {
      this.modalRef = this.modalService.show(this.template, { class: `${this.modalClass}`, backdrop: 'static' });
    }
    else {
      this.swalComponent.show();
    }



  }

  public hide(): void {
    if (!this.fancy) {
      if (this.modalRef !=null)
        this.modalRef.hide();
    }
    else {
      this.swalComponent.nativeSwal.close();
    }

    this.vc = undefined;
  }
}
