import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { AuthGuard } from './auth/auth.guard';
import { CompositeRouteGuard } from './modules/resourceLibrary/views/user/policy/composite.guard';
import { PoliciesModule } from './modules/resourceLibrary/views/user/policy/policies.module';
import { PreferencesGuard } from './modules/resourceLibrary/views/user/preferences/preferences.guard';

/**
 * Main app routing. Includes the initial empty redirect.
 * @type {[{path: string; redirectTo: string; pathMatch: string}]}
 */
const routes: Routes = [
  {
    path: 'home',
    loadChildren: './views/home/home.module#HomeModule',
    canActivate:[AuthGuard]
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: '',
    loadChildren: './views/home/home.module#HomeModule',    
    canActivate: [AuthGuard, CompositeRouteGuard ], //PreferencesGuard
    data: {redirectOverride: '/welcome'}
  },
  {
    path: 'welcome',
    loadChildren: './views/welcome/welcome.module#WelcomeModule',
    canActivate:[AuthGuard]
  },
  {
    path: 'preferences',
    loadChildren: './modules/resourceLibrary/views/user/preferences/preferences.module#PreferencesModule',
    canActivate:[AuthGuard]
  },
  {
    path: 'policy',
    loadChildren: './modules/resourceLibrary/views/user/policy/policies.module#PoliciesModule',
    canActivate:[AuthGuard]
  },
  {
    path: 'logout',
    loadChildren: './views/logout/logout.module#LogoutModule',
  },
  {
    path: 'admin',
    loadChildren: './views/admin/admin.module#AdminModule',
    canActivate:[AuthGuard]
  },

//  { path: '**', redirectTo: '/home', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false, onSameUrlNavigation: 'reload' })],            
  exports: [RouterModule],
  providers: []
})

export class AppRoutingModule {
}
