export class GroupModel {
    groupId?: number;
    groupName: string;
    tenantCode?: string;
    // createdBy?: string;
    // createdDate?: Date;
    // updateBy?: string;
    // updatedDate?: Date;
    description?: string;
    effectiveDate?: Date;
    expiryDate?: Date;
    permissions: GroupPermissionInfo[];
    jobRoleCodes?: string[];
    locationCodes?: string[];
    includedUsers?: number[];
    excludedUsers?: number[];
    isInActive: boolean;
  }
  
  export class GroupPermissionInfo {
    roleId?: number;
    moduleCode: string;
    permissionCode: string;
  }
  
  export class GroupSearchModel {
    searchText?: string;
    status: string;
    effectiveDate?: Date;
    expiryDate?: Date;
  }
  