import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { MenuItem } from 'primeng/api';

import { AppConfigService } from '../../../services/app-config/app-config.service';
import { TenantModuleResolutionService } from '../../../services/tenant/tenant-navigation-resolution-service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-synapze-app-menu',
  templateUrl: './synapze-app-menu.component.html',
  styleUrls: ['./synapze-app-menu.component.css']
})
export class SynapzeAppMenuComponent implements OnInit {

  @Output() searchToggled = new EventEmitter<string>();
  @Input() public adminView = false;
  homepageSearchForm: FormGroup;
  items: MenuItem[];
  selectedItem: MenuItem;
  navigationLinks: any[];
  menuConfiguration = {
    autoDisplay: false
  };

  isSearchToggled = false;
  navbarOpen = false;

  private navMenuItems = [
    {
      moduleCode: 'RESOURCELIBRARY',
      route: '/resources',
      displayName: 'Resources',
      uiModuleCode: 'resource-feed',
      useExternalRoute: false
    },
    {
      moduleCode: 'LEARNINGPATH',
      route: '/learning-path',
      displayName: 'Learn',
      uiModuleCode: 'learning-path',
      useExternalRoute: false,
      children: [
        {
          displayName: 'Core Path',
          route: '/learning-path/core-path',
        },
        {
          displayName: 'Electives',
          route: '/learning-path/electives'
        },
        {
          displayName: 'Manager Requests',
          route: '/learning-path/manager-requests'
        }
      ]
    },
    {
      moduleCode: 'EVENT',
      route: '/event',
      displayName: 'Events',
      uiModuleCode: 'event',
      useExternalRoute: false
    },
      {
          moduleCode: 'NEWS',
          route: '/news',
          displayName: 'News',
          uiModuleCode: 'news',
          useExternalRoute: false
      }
    //{
    //  moduleCode: 'ASSESSMENT',
    //  route: '/assessment',
    //  displayName: 'Test and Trivia',
    //  uiModuleCode: 'test-and-trivia',
    //  useExternalRoute: false
    //}
  ];

  private adminNavMenuItems = [
    {
      moduleCode: 'ADMIN',
      route: '/admin',
      displayName: "Admin",
      uiModuleCode: "admin",
      useExternalRoute: false
    }
  ];

  adminUrl: string;
  mobileVisible = false;
  minCharacterLength = 3;

  constructor(
    private appConfigService: AppConfigService,
    private tenantNavResolutionService: TenantModuleResolutionService,
    private router: Router
  ) { }

  ngOnInit() {
    this.minCharacterLength = this.appConfigService.get().tenantConfig.minimumGlobalSearchFieldLength;

    const adminModule = this.appConfigService.getModule(this.appConfigService.get()
      .tenantConfig.userAdminModuleCode);
    this.adminUrl = adminModule != null ? adminModule.serviceUrl : '';
    //this.tenantNavResolutionService.getEnabledModules(this.adminNavMenuItems, true).forEach(a => this.navigationLinks.push(a));

    if (!this.adminView) {
      this.navigationLinks = this.tenantNavResolutionService.getEnabledModules(this.navMenuItems, false);
      this.items = this.navigationLinks.map(navItem => ({
        label: navItem.displayName,
        routerLink: navItem.route,
        routerLinkActiveOptions: { exact: true },
        items: navItem.children && navItem.children.length > 0 ? navItem.children.map( childrenItem => ({
          label: childrenItem.displayName,
          routerLink: childrenItem.route,
          routerLinkActiveOptions: { exact: true },
        })) : null
      }));
    }

    this.homepageSearchForm = new FormGroup({
      searchtext: new FormControl('', [Validators.required, Validators.minLength(this.minCharacterLength)])
    });


  }

  changeMenu(selectedItem: MenuItem) {
    if (this.selectedItem && this.selectedItem.label === selectedItem.label) {
      this.selectedItem = null;
      return;
    }
    if (selectedItem.items && selectedItem.items.length > 0) {
      this.selectedItem = selectedItem;
      this.navbarOpen = !this.navbarOpen;
    } else {
      this.selectedItem = null;
    }
  }

  isActive(item: MenuItem) {
    return this.selectedItem && this.selectedItem.items && this.selectedItem.items.length > 0 && this.selectedItem.label === item.label;
  }

  itemHasChildren(item: any) {
    return item && item.items && item.items.length > 0;
  }

  goto(url: string) {
    this.router.navigateByUrl(url);
  }
  toggleMobileMenu() {
    this.mobileVisible = !this.mobileVisible;
  }

  toggleSearch() {
    this.isSearchToggled = true;
  }

  hideSearch() {
    this.isSearchToggled = false;
  }

  onSearchSubmit() {
    this.searchToggled.emit(this.homepageSearchForm.controls['searchtext'].value);
    this.isSearchToggled = !this.isSearchToggled;
  }

  onClearSearchInput() {
    //this.isSearchToggled = !this.isSearchToggled;
    this.homepageSearchForm.reset();
  }

}
