import { Component, OnInit, Output, EventEmitter, ViewChild, Inject } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { MenuModule } from 'primeng/menu';

import { AppConfigService } from '../../../services/app-config/app-config.service';
import { TenantModuleResolutionService } from '../../../services/tenant/tenant-navigation-resolution-service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user/user.service';
import { TenantLocale, TenantModel, TenantFooterConfiguration, TenantMetaDataModel } from 'src/app/models/tenantModel';
import { TenantService } from 'src/app/services/tenant/tenant.service';
import { DropDownItemModel } from "src/app/models/DropDownItemModel";
import { TenantNotificationService } from 'src/app/modules/resourceLibrary/views/user/services/tenant-notification.service';
import { TenantNotificationModel } from 'src/app/modules/resourceLibrary/models/tenantNotificationModel';
import { Constants } from 'src/app/shared/shared.constants';
import { Router, ActivatedRoute } from '@angular/router';
import { window, count } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { SynapzeCxModalComponent } from '../synapze-cx-modal/synapze-cx-modal.component';
import { UserModel } from 'src/app/models/UserModel';
import { Location } from '@angular/common';
import { load } from '@angular/core/src/render3';
import { CORE_SESSION_STORAGE } from 'src/app/services/storage/storage.service';
import { StorageService } from 'ngx-webstorage-service';

@Component({
  selector: 'synapze-app-footer',
  templateUrl: './synapze-app-footer.component.html'
})
export class SynapzeAppFooterComponent implements OnInit {

  @ViewChild('policiesModal') policiesModal: SynapzeCxModalComponent;
  @ViewChild('termsConditionsModal') termsConditionsModal: SynapzeCxModalComponent;

  tenantLocales: TenantLocale[];
  languages: DropDownItemModel[] = [];
  termsNotification: TenantNotificationModel;
  privacyNotification: TenantNotificationModel;
  tenantUrls: DropDownItemModel[] = [];
  user: UserModel;
  items: MenuItem[];
  tenantList: MenuItem[];
  currentLanguage: string;
  currentLocale: string;
  currentUrl: string;
  navigationLinks: any[];
  menuConfiguration = {
    autoDisplay: false
  };



  private navMenuItems = [
    {
      moduleCode: 'RESOURCELIBRARY',
      route: '/resources/admin',
      displayName: 'Resource Library',
      uiModuleCode: 'resource-feed',
      useExternalRoute: false
    },
    {
      moduleCode: 'ASSESSMENT',
      route: '/assessment',
      displayName: 'Test and Trivia',
      uiModuleCode: 'test-and-trivia',
      useExternalRoute: false
    }
  ];

  private adminNavMenuItems = [
    {
      moduleCode: 'ADMIN',
      route: '/admin',
      displayName: "Admin",
      uiModuleCode: "admin",
      useExternalRoute: false
    }
  ];

  adminUrl: string;
  mobileVisible = false;
  tenantFooterConfiguration: TenantFooterConfiguration;
  currentYear: number = new Date().getFullYear();

  constructor(
    private appConfigService: AppConfigService,
    private tenantNavResolutionService: TenantModuleResolutionService,
    private userService: UserService,
    private router: Router,
    private tenantNotificationService: TenantNotificationService,
    private tenantService: TenantService,
    private location: Location,
    @Inject(DOCUMENT) private document: any,
    @Inject(CORE_SESSION_STORAGE) private sessionStorage: StorageService) { }

  ngOnInit() {
    this.getUser();
    const adminModule = this.appConfigService.getModule(this.appConfigService.get()
      .tenantConfig.userAdminModuleCode);
    this.adminUrl = adminModule != null ? adminModule.serviceUrl : '';
    this.navigationLinks = this.tenantNavResolutionService.getEnabledModules(this.navMenuItems, false);
    this.tenantNavResolutionService.getEnabledModules(this.adminNavMenuItems, true).forEach(a => this.navigationLinks.push(a));
    var event_occurence = false;
    this.userService.events().subscribe(message => {
      if (message.event == UserService.CURRENT_USER_FETCHED_EVENT) {
        //this.callGTM(this.userService.currentUser());
        if (!event_occurence) {
          this.currentLocale = this.userService.currentUser().browsingLanguagePreference;
          this.getLocales();
        }
        event_occurence = true;
      }
    });

    var userData = this.userService.currentUser();
    if (userData != null && event_occurence == false) {
      this.currentLocale = userData.browsingLanguagePreference;
      this.getLocales();
      event_occurence = true;
    }

    this.tenantService.getTenantMetadata().subscribe(conf => {
      this.tenantFooterConfiguration = conf.tenantFooterConfiguration;
    })

    //this.tenantConfigs = this.tenantconfiguration.tenantFooterConfiguration;
    this.items = this.navigationLinks.map(navItem => ({
      label: navItem.displayName,
      routerLink: navItem.route,
      routerLinkActiveOptions: { exact: true }
    }));

    this.currentLanguage = this.userService.getUserBrowsingLanguage();
    this.userService.events().subscribe(message => {
      if (message.event == UserService.CURRENT_USER_FETCHED_EVENT) {


        this.currentLanguage = this.userService.getUserBrowsingLanguage();
      }
    });

    this.tenantUrls = [];

    this.currentUrl = this.appConfigService.getOrigin();
    var otherTenantUrls = this.appConfigService.get().otherTenantUrls;
    if (otherTenantUrls != null) {

      var items = [];
      otherTenantUrls.forEach(jb => {
        this.tenantUrls = [...this.tenantUrls, { label: jb.name, value: jb.url }];
        items.push({ label: jb.name, styleClass: jb.name, url: jb.url });

      });

      this.tenantList = [{
        items: items
      }];
    }


  }


  showPoliciesModal() {
    var languagePreference = this.sessionStorage.get(Constants.USER_BROWSER_LANGUAGE);
    this.tenantNotificationService.getLastNotification(Constants.PRIVACY_POLICY_TYPE, languagePreference).subscribe(policy => {

      this.privacyNotification = policy;
      this.policiesModal.show();
    });
  }

  showTermsConditionsModal() {
    var languagePreference = this.sessionStorage.get(Constants.USER_BROWSER_LANGUAGE);
    // console.log(languagePreference);
    // console.log(Constants.TERMS_CONDITIONS_TYPE);
    this.tenantNotificationService.getLastNotification(Constants.TERMS_CONDITIONS_TYPE, languagePreference).subscribe(terms => {
      this.termsNotification = terms;
      // console.log(terms);
      this.termsConditionsModal.show();
    });

  }

  closePoliciesModal() {
    this.policiesModal.hide();
  }

  closeTermsModal() {
    this.termsConditionsModal.hide();


  }

  getLocales() {
    this.tenantService.getTenantLocales().subscribe(result => {
      if (result != null) {
        result.forEach(jb => {
          if (jb.localeNames != null) {
            var loc = jb.localeNames.filter(a => a.locale == this.currentLocale);
            loc.forEach(locale => {
              this.languages = [...this.languages, { label: locale.localeName, value: jb.locale }];
            });
          }
          else {
            this.languages = [...this.languages, { label: jb.localeName, value: jb.locale }];
          }
        });
      }

      this.currentLanguage = this.userService.getUserBrowsingLanguage();
    });
  }

  changeUrl(val: any) {
    // console.log(this.router);
    var newURL = `${val}${this.router.url}`;
    // console.log(newURL);
    this.document.location.href = newURL;
  }

  change(val: any) {
    this.userService.updateUserBrowsingLanguage(val).subscribe(s => {
      this.document.location.reload();
    });

  }

  getUser(): void {
    this.user = this.userService.currentUser();
    if (this.user === null || this.user === undefined) {
      this.userService.getUserInfo().subscribe(usr => {
        this.user = usr;
      });
    }
  }
}

