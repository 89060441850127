import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/services/user/user.service';

import { SynapzeCxOAuthserviceService } from './auth/synapze-cx-oauthservice.service';
import { AppConfigService } from './services/app-config/app-config.service';
import { PermissionsService } from './services/permissions/permissions.service';
import { DomSanitizer, SafeUrl, SafeResourceUrl, Title } from '@angular/platform-browser';

import { Constants } from './shared/shared.constants';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { document } from 'ngx-bootstrap';
import { TagManagerService } from 'src/app/services/tagManager/tagManager.service';
import { location } from 'ngx-bootstrap/utils/facade/browser';
import { url } from 'inspector';
import { window } from 'rxjs/operators';
import { UrlModel } from './vos/app-config/app-config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [PermissionsService]
})

/**
 * Main App Component.
 */
export class AppComponent implements OnInit {
  isLoaded: Boolean;

  /**
   * Shown title of view.
   * @type {string}
   */
  title = 'SynapzeCX';
  cssURLS: SafeResourceUrl[] = [];

  constructor(private router: Router,
    private synapzecxoauthService: SynapzeCxOAuthserviceService,
    private permissionsService: PermissionsService,
    private appConfigService: AppConfigService,
    private userService: UserService,
    translate: TranslateService,
    private sanitizer: DomSanitizer,
    private angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    private tagManagerService: TagManagerService,
    titleService: Title) {

    const config = this.appConfigService.get();

    const tenantUrl: UrlModel = config.otherTenantUrls.find(t => t.url === appConfigService.getOrigin());
    if (tenantUrl) {
      titleService.setTitle(tenantUrl.name);
    }

    if (config != null && config.urlMetaData != null) {
      this.cssURLS = config.urlMetaData.cssPackages.map(m => this.sanitizer.bypassSecurityTrustResourceUrl(m));

      //if (config.urlMetaData.gtmSettings != null && config.urlMetaData.gtmSettings.enabled) {
      //  this.appendGaTrackingCode(config.urlMetaData.gtmSettings.gtm_preview, config.urlMetaData.gtmSettings.gtm_auth, config.urlMetaData.gtmSettings.gtm_id);
      //}
    }

    //angulartics2GoogleTagManager.startTracking();
  // // ynapzecxoauthService.Login()); login will be called in the auth guard.

  //  //translate.addLangs(['en', 'fr']);
  //  //translate.setDefaultLang('en');

  //  //const browserLang = translate.getBrowserLang();
  //  //translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');
  }

//  private appendGaTrackingCode(gtm_preview: string, gtm_auth: string, gtm_id: string) {
//    try {

//      const script = document.createElement('script');
//      script.innerHTML = `
//                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
//new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
//j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
//'//www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=${gtm_auth}&gtm_preview=${gtm_preview}&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
//})(window,document,'script','dataLayer','GTM-M2CRQKB');
//      `;
//      document.head.appendChild(script);

//      const bScript = document.createElement('noscript');
//      bScript.innerHTML = `
//              <iframe src="//www.googletagmanager.com/ns.html?id=GTM-M2CRQKB"
//                height="0" width="0" style="display:none;visibility:hidden"></iframe>
//          `;
//      document.body.appendChild(bScript);

//    } catch (ex) {
//      console.error('Error appending google analytics');
//      console.error(ex);
//    }
//  }

  searchEvent(value: string): void {

  }

  /**
   * Called part of the component lifecycle. Best first
   * place to start adding your code.
   */
  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {

        var test = false;
        this.userService.events().subscribe(message => {
          if (message.event == UserService.CURRENT_USER_FETCHED_EVENT) {
            //this.callGTM(this.userService.currentUser());
            if (!test) {
              this.callGTM(this.userService.currentUser());
            }
            test = true;
          }
        });

        var userData = this.userService.currentUser();
        if (userData != null)
          this.callGTM(userData);
        return;
      }
    });
  }

  callGTM(userData: any) {
    var config = this.appConfigService.get()
    if (userData != null) {
      var data = { URL: this.router.url, userCode: userData.userCode, locations: userData.locationNames, jobTitleCode: userData.jobRoleCode, jobTitle: userData.jobRole, pageLanguage: userData.browsingLanguagePreference };
    }
    this.tagManagerService.init([config.urlMetaData.gtmSettings.gtm_id], config.urlMetaData.gtmSettings.gtm_auth, config.urlMetaData.gtmSettings.gtm_preview);
    if (data != null && data != undefined) {
      this.tagManagerService.pushCustomEvent("Page View Router", data);
    }
  }

}
