<ng-container *ngIf="mobileView">
  <div class="mobile-menu-header">
    <div class="mobile-user-area">
      <div class="media-left media-middle">
        <a [ngClass]="{'profile-object-ext': true,'no-profile-picture':noProfilePict}">
          <div class="no-prof-img-area"></div>
          <img *ngIf="user?.pictureUrl!=null" class="media-object-ext"
               [src]="user?.pictureUrl"
               alt="...">
          <!--<img alt="..." class="media-object-ext" src="../../../../assets/css/assets/test_profile.png" />-->
        </a>
        <div class="mobile-user-name"> {{user?.firstName}} {{user?.lastName}}      </div>
      </div>
      <div class="close-mobile-menu">
        <a class="close-menu-link">
          <img alt="close menu" class="close-menu-btn" src="../../../../assets/css/assets/icn-close.png" />
        </a>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!mobileView">
  <div class="media-left media-middle">

    <a [ngClass]="{'profile-object': true,'no-profile-picture':noProfilePict}">
      <div class="no-prof-img-area"></div>
      <img *ngIf="user?.pictureUrl!=null" class="media-object"
           [src]="user?.pictureUrl"
           alt="...">
    </a>
  </div>
  <div class="user-info">
    <div class="arrow-up"></div>
    <div class="profile-link">
      <p>
        {{'core-common-profile_menu_header' | translate: '{ defaultText : "Profile"}' }}
      </p>
      <a [routerLink]="['/profile']">
        <div class="user-menu-img-sm"></div>
        <!--<img src="../../../../assets/icons/menu-user.png" />-->
        <div class="user-menu-img-title">{{user?.firstName}} {{user?.lastName}}</div>
      </a>
    </div>
    <div class="gray-area">
      <a *ngFor="let item of userMenuItems" [routerLink]="[item.routerLink]">
        <div class="switch-menu-img-sm"></div>
        <!--<img src="../../../../assets/icons/menu-switch.png" />-->
        <div class="user-menu-img-title">{{item.label}}</div>
      </a>
      <a routerLink="" (click)="logOut()">
        <div class="logout-menu-img-sm"></div>
        <!--<img src="../../../../assets/icons/menu-logout.png" />-->
        <div class="user-menu-img-title">{{'core-common-profile_menu_logout' | translate: '{ defaultText : "Log Out"}' }}</div>
      </a>
    </div>
  </div>

</ng-container>

  <!--<div class="user-profile-container" [ngClass]="{'infoHidden': !infoVisible}">
    <div class="media user-profile-media">
      <div class="media-left media-middle">
        <a href="#">
          <img class="media-object"
               [src]="user?.pictureUrl"
               alt="...">
        </a>
      </div>
      <div class="media-body" [ngClass]="{'hidden-xs': !infoVisible}">
        <h4 class="media-heading">{{user?.firstName}}</h4>

      </div>
      <div class="user-menu-container">
        <button class="btn btn-link" [ngClass]="{'hidden-xs': !infoVisible}" type="button" (click)="menu.toggle($event)">
          <i class="pi pi-ellipsis-h"></i>
        </button>
      </div>
    </div>
    <p-menu #menu [popup]="true" [model]="userMenuItems" styleClass="user-menu"></p-menu>
  </div>-->

  <synapze-cx-modal modalClass="modal-md" #logOutConfirmation>
    <div header>Confirm Logout</div>
    <div body>


      ut
      <span>

        Are you sure you want to logo
        ut no
        w ?
      </span>
      no
    </div>
    no
    <div footer>
      no
      <button class="btn btn-danger" type="button" (click)="logOut()"><strong>Logout</strong></button>&nbsp;&nbsp;
      <button class="btn btn-success" type="button" (click)="cancellogOutConfirmationModal()"> Cancel</button>&nbsp;&nbsp;
    </div>
  </synapze-cx-modal>
