import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { Route, RouterModule } from '@angular/router';
import { CategoryConfigResolver } from './category.resolver';
import { PreferencesComponent } from './preferences.component';
import { PreferencesResolver } from './preferences.guard';
import { UserCategoriesResolver } from './user.categories.resolver';
import { SynapzeComponentsModule } from '../../../../../components/synapze-shareable-components/synapze-components.module';
import { CategoryControlComponent } from '../components/category-control/category-control.component';
import { ConfirmationModalComponent } from '../components/confirmation-modal/confirmation-modal.component';
import { CategoriesService } from '../../../services/categories.service';
import { TranslateModule, TranslateLoader, MissingTranslationHandler } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from 'src/app/services/app-config/app-config.service';
import { LocalizationLoader, MyMissingTranslationHandler } from 'src/app/services/localization-loader.service';


const routes: Route[] = [
  {
    path: '',
    component: PreferencesComponent,
    resolve: {
      categories: PreferencesResolver,
      config: CategoryConfigResolver,
      userCategories: UserCategoriesResolver
    }
  }
];

export function HttpLoaderFactory(
  http: HttpClient,
  appConfigService: AppConfigService
) {
  // return new TranslateHttpLoader(http, '/public/lang-files/', '-lang.json');
  return new LocalizationLoader(
    http,
    'ResourceLibrary',
    'Core',
    appConfigService
  );
}


@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ReactiveFormsModule,
    MatSnackBarModule,
    SynapzeComponentsModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient, AppConfigService]
      },
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MyMissingTranslationHandler },
      isolate: true
    }),
  ],
  declarations: [PreferencesComponent,
    CategoryControlComponent,
    ConfirmationModalComponent],
  providers: [CategoriesService],
  exports: [PreferencesComponent, CategoryControlComponent, ConfirmationModalComponent]
})
export class PreferencesModule { }
