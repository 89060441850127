import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { CategoriesService } from '../../../services/categories.service';

@Injectable({
  providedIn: 'root'
})
export class UserCategoriesResolver implements Resolve<any> {

  constructor(private categoriesService: CategoriesService, private router: Router) { }

  resolve() {
    return this.categoriesService.getUserCategories();
  }
}
