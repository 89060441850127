import { Injectable, Optional } from '@angular/core';
import { AppConfigService } from '../app-config/app-config.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { SearchResponseModel } from 'src/app/models/search';
import { dataGridModel } from 'src/app/models/dataGridModel';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { camelCase } from 'lodash';
import { TenantModulesModel } from 'src/app/models/tenantModel';

const camelizeKeys = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map(v => camelizeKeys(v));
  } else if (obj !== null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [camelCase(key)]: camelizeKeys(obj[key]),
      }),
      {},
    );
  }
  return obj;
};

@Injectable({
  providedIn: 'root'
})
export class HomePageSearchService {

  /**
 * Path uri.
 * @type {string}
 * @private
 */
  private _uri = '/api/GlobalSearch';

  /**
   * Url to endpoint api.
   * @type {string}
   */
  private endpoint = '';

  private _appConfigService: AppConfigService;

  /**
   * Endpoint request headers.
   * @type {HttpHeaders}
   */
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private http: HttpClient, @Optional() appConfigService: AppConfigService) {
    this._appConfigService = appConfigService;
    if (this._appConfigService) {
      this.endpoint = this._appConfigService.get().tenantConfig.coreService.serviceUrl;
      this._uri = `${this.endpoint}` + this._uri;
    }
  }

  /**
* validate the resource.
* @returns {Observable<SearchResponseModel>}
*/
  search<T>(searchParameters: dataGridModel<T>): Observable<SearchResponseModel> {
    var endpoint = '';
    return this.http.post<SearchResponseModel>(`${this._uri}${endpoint}/search`, searchParameters)
      .pipe(
        tap(t => {
          //grab the media..
          if (t != null && t.result != null) {
            t.result.forEach(res => {
              if (res.items != null)
                res.items = JSON.parse(JSON.stringify(camelizeKeys(res.items)));

            });
          }
        })
      );
  }
  
  getTenantModules(): Observable<TenantModulesModel[]> {
    var endpoint = '/Modules';
    return this.http.get<TenantModulesModel[]>(`${this._uri}${endpoint}`);
  }

}
