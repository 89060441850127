import { Injectable, Optional, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';

import { ToastrService } from 'ngx-toastr';
import { Console } from '@angular/core/src/console';



@Injectable()
/**
 * Service class.
 */
export class SynapzeToastrService {

  constructor(private toastr: ToastrService) {  }

  

  /**
   * Pulls a list of modules
   * @returns {Observable<RoleModel[]>}
   */
  showSuccess(footerContent: string, message: string): void{
    this.toastr.success(message, footerContent);
  }

  showError(footerContent: string, message: string): void{
    this.toastr.error(message, footerContent);
  }
}
