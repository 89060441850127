import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DurationTypeNamePipe } from '../../../pipes/duration-name-pipe';
import { CategoryNamesPipe } from '../../../../../pipes/category-ids-names-pipe';
import { CategoryNamePipe } from 'src/app/pipes/category-name-pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    CategoryNamesPipe,
    DurationTypeNamePipe
  ],
  exports: [
    CategoryNamesPipe,
    DurationTypeNamePipe
  ]
})
export class ResourcesSharedComponentsModule {

}
