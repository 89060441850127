import { Listener } from 'selenium-webdriver';
import { BaseModel } from './baseModel';
import { NumberSymbol } from '@angular/common';

export class TenantModel {
  tenantCode?: string;
  tenantName?: string;
}


export class TenantModulesModel {
  moduleName?: string;
  moduleId?: string;
  moduleCode?: string;
  moduleUrl?: string;
  modulePermissions: TenantModulesPermissionModel[];
}

export class TenantModulesPermissionModel extends BaseModel {
  permissionId?: string;
  permissionName?: string;
  permissionCode ?: string;
}


export class TenantJobDesignationModel extends BaseModel {
  jobDesignationCode?: string;
  jobDesignationId?: number;
  jobDesignationName?: string;
  locales?: TenantJobDesignationLocaleModel[];
}

export class TenantJobDesignationLocaleModel extends BaseModel {
  jobDesignationId?: string;
  jobDesignationName?: string;
  locale?: string;
}

export class TenantLocationTypeModel {
  locationType: string;
  locationTypeLevel: string;
  locationLocale: string;
}

export class TenantTagModel {
  tagType: string;
  tagCode: string;
}

export class TenantBussinessTypeModel {
  businessTypeId: number;
  businessTypeCode: string;
  businessTypeName: string;
}

export class TenantLocale {
  locale: string;
  localeName: string;
  localeNames?: LocaleNames[];
}

export class CategoryPreferenceConfiguration {
  minCategoriesAllowed: number;
  maxCategoriesAllowed: number;
  updateFrequencyPeriod: number;
}

export class TenantConfigurationModel {
  resourceNameMaxLength: number;
  assetNameMaxLength: number;
  categoryPreferenceConfiguration: CategoryPreferenceConfiguration;
  searchExploreWatermark: string;
  allowToDownloadAssets: boolean;
  userAssetUploadTermsCondAlways: boolean;
}

export class TenantQuestionsConfigurationModel {
  count: number;
  minimumAnswerLength: number;
  maximumAnswerLength: number;
  questionSets: any;
}

export class TenantPasswordConfigurationModel {
  minimumLength: number;
  maximumLength: number;
  cannotBeSameAsUserName: boolean;
  shouldAtleastHave1UpperCase: boolean;
  shouldAtleastHave1LowerCase: boolean;
  shouldAtleastHave1Number: boolean;
  cannotHaveConsecutiveCharacters: boolean;
  customRegEx: string;
  customRegExDescription: string;
  maxRetryAttempt: number;
  lockOutPeriodInMinutes: number;
  excludedCharacters: string;
}

export class TenantSecurityQuestionConfigurationModel {
  count: number;
  minimumAnswerLength: number;
  maximumAnswerLength: number;
  questionSets: any[];
}

export class TenantRegistrationConfigurationModel {
  differentEmailAndUserName: boolean;
  userNameMinLength: number;
  userNameMaxLength: number;
  userNameExcludedCharacters: string;
}


export class TenantMetaDataModel {
  availableLocales: TenantLocationTypeModel[];
  passwordConfiguration: TenantPasswordConfigurationModel;
  registrationConfiguration: TenantRegistrationConfigurationModel;
  userProfileConfiguration: any;
  tenantWebSite: string;
  rememberMeLoginDuration: string;
  tokenExpirationInMinutes: number;
  securityQuestionConfiguration: TenantSecurityQuestionConfigurationModel;
  tenantFooterConfiguration: TenantFooterConfiguration;
  tenantLocationFormat: string;
  locationTypeLocale: LocationTypesLocale;
  locales: Locales[];
}


export class TenantDashboardWidgetModel {
  widgetCode?: string;
  sortOrder?: number;
  metadata?: any;
  widgetInfo: DashboardWidgetModel;
}
export class DashboardWidgetModel {
  widgetDescription?: string;
  moduleCode?: string;
  isExternalModule?: boolean;
  componentToInvoke?: string;
  pathToModuleFile?: string;
}

export class TenantFooterSocialMediaConfiguration {
  mediaName?: string;
  mediaUrl?: string;
}

export class TenantFooterLinkConfiguration {
  linkName?: string;
  linkUrl?: string;
}

export class TenantFooterConfiguration {
  tenantFooterSocialMediaConfigurations?: TenantFooterSocialMediaConfiguration[];
  showLogo?: boolean;
  termsAndConditions?: boolean;
  privacy?: boolean;
  additionalLinks?: TenantFooterLinkConfiguration[];
  submitAnIssue?: boolean;
  contactNumber?: string;
  showLanguageSwitchingOption?: boolean;
  poweredBySynapzeCX?: boolean;
  copyright?: boolean;
}

export class LocationLevelType {
  level?: string;
  locale?: string;
  type?: string;
}

export class LocationTypesLocale {
  locationLevelType?: LocationLevelType[];
}

export class Locales {
  locale?: string;
  localeNames?: LocaleNames[];
}

export class LocaleNames {
  locale?: string;
  localeName?: string;
}
