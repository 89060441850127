import { AppConfigService } from 'src/app/services/app-config/app-config.service';
import { PermissionsService } from 'src/app/services/permissions/permissions.service';
import { NavigationItem } from 'src/app/vos/app-config/app-config';
import { concat } from 'rxjs';

export class TenantModuleResolutionService {
  constructor(private appConfigService: AppConfigService, private permissionsService: PermissionsService) {

  }

  getEnabledModules(navMenuItems: any[], checkPermissions = true): any[] {

    const enabledModules: any[] = [];
    const modules = this.appConfigService.get().tenantConfig.modules;
    if (modules != null && modules.length > 0) {
      modules.forEach(x => {
        const navItem = navMenuItems.filter(item => item.moduleCode === x.moduleCode);
        if (navItem != null && navItem.length > 0) {
          const item = navItem[0];
          //if (item.useExternalRoute === true) {
          //  item.route = x.spaUrl;
          //}
          if (item.useExternalRoute === true) {
            item.route = x.spaUrl + item.route;

            if (item.children != null) {
              item.children.forEach(ch => {
                ch.route = x.spaUrl + ch.route;
              });
           }

          }

          if (item.children != null) {
            item.children = item.children.filter(ch => ch.permissions == null || !checkPermissions || this.permissionsService.checkUserPermissions(ch.permissions, false));
          }


          item.displayName = x.displayName;
          //item.permissions = x.permissions;

          if (item.permissions == null || item.permissions.length == 0 || !checkPermissions || this.permissionsService.checkUserPermissions(item.permissions, false))
            enabledModules.push(item);
        }
      })
    }

    return enabledModules;
  }

  hasAnyModuleAdminPermission(): boolean {
    const enabledModules: any[] = [];
    const modules = this.appConfigService.get().tenantConfig.modules;
    var retVal = false;
    if (modules != null && modules.length > 0) {
      modules.forEach(x => {
        if (!retVal) {
          var premissions = x.permissions.filter(a => a != "RESOURCELIBRARY_USER_UPLOAD");
          retVal = this.permissionsService.checkUserPermissions(premissions, false);

        }
      })
    }

    return retVal;
  }

  getEnabledReports(navMenuItems: NavigationItem[]): any[] {
    let enabledReports: any;
    const reportsChilds: any[] = [];
    const permissions = this.permissionsService.userPermissions();
    navMenuItems.forEach(navItem => {
      if (permissions.includes(`${navItem.moduleCode}_VIEW_REPORTS`)) {
        reportsChilds.push({
          moduleCode: navItem.moduleCode,
          displayName: navItem.displayName,
          route: `${navItem.route}/reports`
        });
      }
    });
    if (reportsChilds.length > 0) {
      enabledReports = {
        moduleCode: 'REPORTS',
        route: 'reports',
        displayName: 'Reports',
        useExternalRoute: false,
        children: reportsChilds
      };
    }
    return enabledReports;
  }
}
