import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TranslateService, TranslateModule, MissingTranslationHandler } from '@ngx-translate/core';
import { SynapzeComponentsModule } from '../../../../../../components/synapze-shareable-components/synapze-components.module';
import { MyMissingTranslationHandler } from '../../../../../../services/localization-loader.service';
import { ResourcesSharedComponentsModule } from '../../modules/resources-shared-components.module';
import { SearchAssetsComponent } from './search-assets.component';
import { ResultListItemComponent } from '../result-list-item/result-list-item.component';
import { SearchAssetsService } from '../../services/search-assets.service';
import { CategoriesService } from '../../../../services/categories.service';

@NgModule({
  imports: [
    CommonModule,
    SelectButtonModule,
    FormsModule,
    ProgressSpinnerModule,
    SynapzeComponentsModule,
    RouterModule,
    TranslateModule.forChild({
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MyMissingTranslationHandler },
      isolate: true
    }),
    InfiniteScrollModule,
    ResourcesSharedComponentsModule
  ],
  declarations: [SearchAssetsComponent, ResultListItemComponent],
  providers: [SearchAssetsService, CategoriesService],
  exports: [SearchAssetsComponent, ResultListItemComponent]
})
export class SearchAssetsModule { }
