<div class="content-loader-wrapper" [ngClass]="uiModel.wrapperStyle">
  <div *ngIf="uiModel.isPending" class="content-loader" [ngClass]="uiModel.styles">
    <div class="loader">
    </div>
    <div class="message">
      {{uiModel.messageToShow}}
    </div>
  </div>
  <ng-content *ngIf="uiModel.isReady || uiModel.isTransparent"></ng-content>
</div>
