import { Component, OnInit, Type, Injectable } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/api';

@Injectable()
/**
 * Service class.
 */
export class SynapzeCxDialogService  {

  constructor(private dialogService:DialogService) { }
 // ref:DynamicDialogRef;
  

  show(componentType: Type<any>,showClose:boolean){
    var ref = this.dialogService
                  .open(componentType,
                          {
                            closable:showClose,
                            closeOnEscape:true                            
                          });
    
  }

  // public closeDialog() {
  //     this.ref.close(); 
  // }

}
