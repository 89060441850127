import { Observable, of } from 'rxjs';
import { BaseResultModel } from 'src/app/models/baseResultModel';
import { PathModel, PathItemsModel, CurrentCorePathInfo } from '../models/pathModel';
import { UserPathModel } from '../models/userpathModel';
import { AppConfigService } from 'src/app/services/app-config/app-config.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Optional } from '@angular/core';
import { camelCase } from 'lodash';
import { SearchResponseModel } from 'src/app/models/search/searchResponseModel';
import { tap, map } from 'rxjs/operators';



const camelizeKeys = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map(v => camelizeKeys(v));
  } else if (obj !== null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({ ...result, [camelCase(key)]: camelizeKeys(obj[key]), }),
      {},
    );
  }
  return obj;
};




export class CorePathService {

  private _uri = '/api/CorePath';
  private _pathInfoUri = '/api/PathInfo';

  private endpoint = '';

  private _appConfigService: AppConfigService;

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private http: HttpClient, @Optional() appConfigService: AppConfigService) {
    this._appConfigService = appConfigService;
    if (this._appConfigService) {
      const learningPathModule = this._appConfigService.get().tenantConfig.modules.find(x => x.moduleCode === 'LEARNINGPATH');
      this.endpoint = learningPathModule.serviceUrl;
      this._uri = `${this.endpoint}` + this._uri;
      this._pathInfoUri = `${this.endpoint}` + this._pathInfoUri;
    }
  }

  getUserCurrentCorePath(): Observable<CurrentCorePathInfo> {
    const endpoint = `${this._uri}`;
    return this.http.get<CurrentCorePathInfo>(endpoint)
      .pipe(
        map(t => {
          if (t != null) {
                return JSON.parse(JSON.stringify(camelizeKeys(t)));
          }
        })
      );
  }

  setUserCurrentPath(userPath: UserPathModel): Observable<BaseResultModel<PathModel>> {
    const endpoint = `${this._uri}/setUserPath`;
    return this.http.post<BaseResultModel<PathModel>>(endpoint, userPath);
  }

  getTenantPaths(): Observable<BaseResultModel<PathModel[]>> {
    const endpoint = `${this._uri}/tenantPaths`;
    return this.http.get<BaseResultModel<PathModel[]>>(endpoint);
  }

  getJobRolePaths(): Observable<BaseResultModel<PathModel[]>> {
    const endpoint = `${this._uri}/jobRolePaths`;
    return this.http.get<BaseResultModel<PathModel[]>>(endpoint);
  }

  getPathItems(): Observable<BaseResultModel<PathItemsModel>> {
    const endpoint = `${this._uri}/getPathItems`;
    return this.http.get<BaseResultModel<PathItemsModel>>(endpoint);
  }


}
