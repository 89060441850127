import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import {Injectable, Injector, Inject} from '@angular/core';
import { Observable, of, forkJoin } from "rxjs";
import { TenantNotificationService } from '../services/tenant-notification.service';
import { CategoriesService } from '../../../services/categories.service';
import { UserModel } from 'src/app/models/UserModel';
import { TenantNotificationModel } from '../../../models/tenantNotificationModel';
import { Constants } from 'src/app/shared/shared.constants';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import { UserService } from 'src/app/services/user/user.service';
import { CORE_SESSION_STORAGE } from 'src/app/services/storage/storage.service';
import { StorageService } from 'ngx-webstorage-service';

@Injectable({
  providedIn: 'root'
})
export class CompositeRouteGuard implements CanActivate {

  constructor(private router: Router,
              private tenantNotificationService : TenantNotificationService,
              private userService: UserService,
              private categoriesService: CategoriesService,
              @Inject(CORE_SESSION_STORAGE) private sessionStorage: StorageService ) {}

  user: UserModel;
  getPrivacy: Observable<TenantNotificationModel>;
  getTerms: Observable<TenantNotificationModel>;
  language: string = "";
  userCategories$ = this.getUserCategories();
  redirectRouteOverride:string;

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean>|Observable<boolean>{


    this.language = this.sessionStorage.get(Constants.USER_BROWSER_LANGUAGE);
    if (this.language === undefined || this.language == null || this.language == "")
    this.language = this.userService.getUserBrowsingLanguage();

    this.getPrivacy = this.tenantNotificationService.getTenantNotification(Constants.PRIVACY_POLICY_TYPE,this.language);
    this.getTerms = this.tenantNotificationService.getTenantNotification(Constants.TERMS_CONDITIONS_TYPE,this.language);
   

      return new Promise((resolve, reject) => {
        forkJoin(
          this.getPrivacy,
          this.getTerms
        )
          .pipe(
            map(response => ({ privacy: response[0], terms: response[1] }))
          )
          .subscribe(
            (response) =>
          {
              if(response.privacy != null) this.sessionStorage.set(Constants.PRIVACY_UP_TO_DATE, "false");
              else this.sessionStorage.set(Constants.PRIVACY_UP_TO_DATE, "true");
              if(response.terms != null) this.sessionStorage.set(Constants.TERMS_UP_TO_DATE, "false");
              else this.sessionStorage.set(Constants.TERMS_UP_TO_DATE, "true");
              if(response.privacy == null && response.terms == null){
                    
                this.redirectRouteOverride = route.data["redirectOverride"] as string;
                    // in case user skipped the preference, we do not want to show welcome page again.
                    var preferenceSkipped = this.sessionStorage.get(Constants.WELCOME_SKIPPED);
                    // console.log(this.userCategories$);
                    if(preferenceSkipped== "true"){
                      return  resolve(true);
                    }
                    else
                    { 
                  
                        forkJoin(
                          this.userCategories$,
                          this.categoriesService.getCategoriesConfiguration()
                        )
                          .pipe(
                            map(response => ({ userCategories: response[0], config: response[1] }))
                          )
                          .subscribe(
                            (response) => 
                          {
                            const userCategories = response.userCategories;
                            if (this.checkEmptyCategories(userCategories)) {
                              // console.log('redirecting cause categories are empty');
                              this.redirectUser();
                              return resolve(false);
                            }
                            if (this.checkFrequencyPeriod(userCategories, response.config)) 
                            {
                              // console.log('redirecting... categories are old enough to redirect');
                              this.redirectUser();
                              return resolve(false);
                            }
                            if (this.checkUpdatedCategories(userCategories)) {
                              // console.log('redirecting... one or more categories were updated');
                              this.redirectUser();
                              return resolve(false);
                            }
                            if (this.checkNewCategories(userCategories)) {
                              // console.log('redirecting... one or more categories were inserted');
                              this.redirectUser();
                              return resolve(false);
                            }
                            resolve(true);
                          });
                     
                    }


                }
                else {
                    this.router.navigate(['/policy']);
                    return resolve(false);
                }
            });
          });     

  }

  private redirectUser() {
    // console.log(this.redirectRouteOverride);
    if(this.redirectRouteOverride!=null&& this.redirectRouteOverride !=''){
      // console.log("Redirecting to "+this.redirectRouteOverride);
      this.router.navigate([this.redirectRouteOverride]);
    }
      else{
      this.router.navigate(['/preferences']);
      }  
  }

  private checkEmptyCategories(userCategories: any[]): boolean {
    return userCategories == null || userCategories.length === 0;
  }

  private checkFrequencyPeriod(categories: any[], config: any): boolean {
    return categories.some(cat => {
      return moment().diff(moment(cat['updatedAt']), 'days') > config.updateFrequencyPeriod;
    });
  }

  private checkUpdatedCategories(categories: any[]): boolean {
    return categories.some(cat => cat['isModified']);
  }

  private checkNewCategories(categories: any[]): boolean {
    return categories.some(cat => cat['isInserted']);
  }

  private getUserCategories(): Observable<any[]> {
    return this.categoriesService.getUserCategories();
  }

}
