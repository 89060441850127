import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfigService } from '../app-config/app-config.service';
import { AppConfig } from 'src/app/vos/app-config/app-config';
import { UserModel, UserChangePassword, UserWelcomeStateModel } from '../../models/UserModel';
import { of } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from 'src/app/shared/shared.constants';
import { Observable, Subject } from 'rxjs';
import { SynapzeCxOAuthserviceService } from 'src/app/auth/synapze-cx-oauthservice.service';
import { CORE_SESSION_STORAGE } from '../storage/storage.service';
import { StorageService } from 'ngx-webstorage-service';


@Injectable()
/**
 * Service class.
 */
export class LocalizationService {

  static readonly LANGUAGE_CHANGE_EVENT = "languageChangeEvent";
  private subject = new Subject<any>();

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private http: HttpClient,
    private translate: TranslateService,
    @Inject(CORE_SESSION_STORAGE) private sessionStorage: StorageService
    ) {
  }

  setUserBrowsingLanguage(locale: string) {
    if (locale != null && locale != '') {
      this.sessionStorage.set(Constants.USER_BROWSER_LANGUAGE, locale);
      this.useBrowsingLanguage();
    }
  }


  useBrowsingLanguage(): void {
    //var language = this.getUserBrowsingLanguage();
    //this.translate.use(language);
    this.subject.next({ event: LocalizationService.LANGUAGE_CHANGE_EVENT });
  }
}
