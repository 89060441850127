import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';


import { LogoutComponent } from './logout.component';
import { TranslateModule, TranslateService, MissingTranslationHandler } from '@ngx-translate/core';
import { MyMissingTranslationHandler } from 'src/app/services/localization-loader.service';

/**
 * Add module routing here.
 * @type {[{path: string; component: PageNotFoundComponent}]}
 */
const routes: Routes = [
  {
    path: '',
    component: LogoutComponent
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    TranslateModule.forChild({
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MyMissingTranslationHandler },
      isolate: true
    }),
  ],
  declarations: [LogoutComponent],
  exports: [RouterModule],
})
export class LogoutModule {
}
