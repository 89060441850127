import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CollapseModule } from 'ngx-bootstrap';
import { HomeComponent } from './home.component';
import { HomePageSearchComponent } from './home-page-search/home-page-search.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateModule, TranslateLoader, TranslatePipe, TranslateService, MissingTranslationHandler, TranslateParser } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { TabMenuModule } from 'primeng/tabmenu';
import { DynamicDialogComponent } from 'primeng/components/dynamicdialog/dynamicdialog';
import { DialogService } from 'primeng/api';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { PreferencesGuard } from '../../modules/resourceLibrary/views/user/preferences/preferences.guard';
import { ConfirmationModalComponent } from '../../modules/resourceLibrary/views/user/components/confirmation-modal/confirmation-modal.component';
import { SynapzeComponentsModule } from '../../components/synapze-shareable-components/synapze-components.module';
import { LocalizationLoader, MyMissingTranslationHandler, CustomTranslateParser } from '../../services/localization-loader.service';
import { SearchAssetsModule } from '../../modules/resourceLibrary/views/user/components/search-assets/search-assets.module';
import { TenantModuleResolutionService } from '../../services/tenant/tenant-navigation-resolution-service';
import { DynamicComponentManifest, DynamicComponentLoaderModule } from '../../components/dynamic-component-loader/dynamic-component-loader.module';
import { CorePathService } from '../../modules/learning-path/services/corePath.service';

import { LandingPageRedirectGuard } from './landingPage.redirect.guard';

import { BaseAppModule } from 'src/app/app.base.module';
import { UserService } from 'src/app/services/user/user.service';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from 'src/app/services/app-config/app-config.service';

export function HttpLoaderFactory(http: HttpClient, appConfigService: AppConfigService) {
  return new LocalizationLoader(http, 'Common', 'Core', appConfigService);
}



const manifests: DynamicComponentManifest[] = [
  {
    componentId: 'resourceHomePageSearch-widget',
    path: 'dynamic-resourceHomePageSearch-widget', // some globally-unique identifier, used internally by the router
    loadChildren: 'src/app/modules/resourceLibrary/views/widgets/home-page-search/home-page-search.module#HomePageSearchModule'
  },
  {
    componentId: 'assessmentHomePageSearch-widget',
    path: 'dynamic-assessmentHomePageSearch-widget', // some globally-unique identifier, used internally by the router
    loadChildren: 'src/app/modules/assessment/views/user/assessment-home-page-search/assessment-home-page-search.module#AssessmentHomePageSearchModule'
  }
];

const routes: Routes = [
  {
    path: '',
    //redirectTo: 'dashboard',
    component: HomeComponent,
    canActivate: [LandingPageRedirectGuard],
    pathMatch: 'full',
  },
  {
    path: '',
    component: HomeComponent,
    //canActivate: [LandingPageRedirectGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: '../dashboard/dashboard.module#DashboardModule'
      },
      {
        path: 'explore',
        loadChildren: '../explore/explore.module#ExploreModule'
        //canActivate: [PreferencesGuard],
        //data: {redirectOverride: '/preferences'}
      },
      {
        path: 'resources',
        loadChildren: '../../modules/resourceLibrary/views/user/modules/resource-feed.module#ResourceFeedModule',
        canActivate: [PreferencesGuard],
        data: {redirectOverride: '/preferences'}
      },
      {
        path: 'assessment',
        loadChildren: '../../modules/assessment/views/user/user-assessment.module#UserAssessmentModule',
      },
      {
        path: 'event',
        loadChildren: '../../modules/event/views/user/user-event.module#UserEventModule',
      },
      {
        path: 'profile',
        loadChildren: '../../modules/user-profile/user-profile.module#UserProfileModule'
      },
      {
        path: 'learning-path',
        loadChildren: '../../modules/learning-path/views/user/learn.module#LearnModule',
      },
      {
        path: 'news',
        loadChildren: '../../modules/news/views/user/news.module#NewsFeedModule',
      }
    ]
  },

];

@NgModule({
  declarations: [
    HomeComponent, HomePageSearchComponent
  ],
  imports: [
    FormsModule,
    RouterModule.forChild(routes),
    CollapseModule,
    CommonModule,
    MatButtonToggleModule,
    SynapzeComponentsModule.forRoot(),
    TabMenuModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient, AppConfigService]
      },
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MyMissingTranslationHandler },
      parser: { provide: TranslateParser, useClass: CustomTranslateParser },
      isolate: true,

    }),
    SearchAssetsModule,
    ReactiveFormsModule,
    DynamicComponentLoaderModule.forRoot(manifests),
  ],
  entryComponents:[ConfirmationModalComponent,DynamicDialogComponent],
  exports: [
    RouterModule,
    SynapzeComponentsModule
    //,CategoryNamesPipe
  ],
  providers: [
    TenantModuleResolutionService,
    DialogService,
    CorePathService
  ]
})

export class HomeModule {
}

//export class HomeModule extends BaseAppModule {
//  constructor(private translate: TranslateService, private userService: UserService) {
//    super();
//    //console.log("MAIN LOADED");
//    this.setTranslationLocale(translate, userService);
//  }
//}
