import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable()
export class CustomHttpInterceptorService implements HttpInterceptor {

  constructor(public authService: OAuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authService.getAccessToken();
    let nextReq = req.clone();

    if (token != null && token !== '') {
      nextReq = req.clone({
        headers: req.headers
          .set('Content-Type', 'application/json')
          .set('Authorization', `Bearer ${this.authService.getAccessToken()}`)
      });
    }
    return next.handle(nextReq);
  }
}
