import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { MissingTranslationHandler, TranslateModule, TranslateParser } from '@ngx-translate/core';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { FileUploadModule } from 'ng2-file-upload';
import { ModalModule } from 'ngx-bootstrap/modal';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ConfirmationService, DialogService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MultiSelectModule } from 'primeng/multiselect';
import { PanelMenuModule } from 'primeng/panelmenu';
import { SidebarModule } from 'primeng/sidebar';
import { CalendarModule } from 'primeng/calendar';
import { JobRoleNamePipe } from 'src/app/pipes/job-role-name-pipe';
import { LocationNamePipe } from 'src/app/pipes/location-name-pipe';
import { TenantRoleNamePipe } from 'src/app/pipes/tenant-role-name-pipe';
import { MyMissingTranslationHandler, CustomTranslateParser } from 'src/app/services/localization-loader.service';
import { LocationsService } from 'src/app/services/locations/locations.service';
import { MediaService } from 'src/app/services/media/media.service';
import { TenantService } from 'src/app/services/tenant/tenant.service';

import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { CustomTagsComponent } from './custom-tags/custom-tags.component';
import { FileUploadImagePreviewerComponent } from './fileUpload-image-Previewer/fileUpload-image-Previewer.component';
import { JobRoleTenantRoleSelectorComponent } from './jobRole-tenantRole-selector/jobRole-tenantRole-selector.component';
import { PermissionBlockComponent } from './permission-block/permission-block.component';
import { PlayPodcastComponent } from './play-podcast/play-podcast.component';
import { PlayVideoComponent } from './play-video/play-video.component';
import { SearchComponent } from './search/search.component';
import { SecuredImageComponent } from './secured-image/secured-image.component';
import { SynapzeAppMenuComponent } from './synapze-app-menu/synapze-app-menu.component';
import { SynapzeCXGridColumnComponent } from './synapze-cx-grid/synapze-cx-grid-column.component';
import { SynapzeCXGridRowComponent } from './synapze-cx-grid/synapze-cx-grid-row.component';
import { SynapzeCXGridComponent } from './synapze-cx-grid/synapze-cx-grid.component';
import { SynapzeCxInputSwitchComponent } from './synapze-cx-input-switch/synapze-cx-input-switch.component';
import { SynapzeCxModalComponent } from './synapze-cx-modal/synapze-cx-modal.component';
import { SynapzeCxRichTextComponent } from './synapze-cx-rich-text/synapze-cx-rich-text.component';
import { PeAutoscrollFixDirective } from './synapze-cx-rich-text/p-editor-model.directive';
import { SynapzeCxSimpleDropDownComponent } from './synapze-cx-simple-dropdown/synapze-cx-simple-dropdown.component';
import { TenantLocaleSelectorComponent } from './tenant-locale-selector/tenant-locale-selector.component';
import { TenantLocationSelectorComponent } from './tenant-location-selector/tenant-location-selector.component';
import { SynapzeToastrService } from './toastr/toastr.service';
import { UploadImageWithPreviewComponent } from './upload-image-withPreview/upload-image-withPreview.component';
import { UploadMediaComponent } from './upload-media/upload-media.component';
import { ValidationErrorComponent } from './validation-errors/validation-error.component';
import { ValidationSummaryComponent } from './validation-errors/validation-summary.component';
import { ProfileComponent } from './profile/profile.component';
import { SynapzeBlockUIService } from '../synapze-shareable-components/block-ui/blockui.service'
import { SynapzeCxDisplayMediaComponent } from '../synapze-shareable-components/synapze-cx-display-media/synapze-cx-display-media.component'
import { BlockUIComponent } from './block-ui/block-ui.component';
import { BlockUIModule } from 'ng-block-ui';
import { CarouselModule } from 'primeng/carousel';
import { ProgressBarModule } from 'primeng/progressbar';
import { SynapzeAppFooterComponent } from './synapze-app-footer/synapze-app-footer.component';
import { SynapzeCxDialogService } from './synapze-cx-dialog/synapzedialog.service';
import { SynapzeCxCalendarComponent } from './synapze-cx-calendar/synapze-cx-calendar.component'
import { SynapzeCxFormControlComponent } from './synapze-cx-form-control/synapze-cx-form-control.component';
import { ClickOutsideDirective } from './click-outside.directive';
import { CustomScrollDirective } from './custom-scroll.directive';
import { ContentEditableInputDirective } from 'src/app/components/synapze-shareable-components/content-editable-input.directive';
import { ContentLoaderComponent } from 'src/app/components/synapze-shareable-components/content-loader/content-loader.component';
import { SynapzeCxButtonComponent } from 'src/app/components/synapze-shareable-components/synapze-cx-button/synapze-cx-button.component';
import { SynapzeCxSortableComponent } from './synapze-cx-sortable/synapze-cx-sortable.component';
import { CustomTagsFormControlComponent } from './custom-tags-form-control/custom-tags-form-control.component';
import { TenantTagCodeSelectorComponent } from './tenant-tag-selector/tenant-tag-code-selector.component';
import { GroupsService } from 'src/app/services/groups/group.service';
import { GroupNamePipe } from 'src/app/pipes/group-name-pipe';
import { CardModule } from 'primeng/card';
import { AppComponentContainer } from './synapze-cx-modal/synapze-cx-modal.component';
import { TenantCategorySelectorDropDownComponent } from './tenant-category-selector/tenant-category-selector-dropdown.component';
import { TenantCategorySelectorComponent } from './tenant-category-selector/tenantCategory-selector.component';
import { TenantCategorySearchSelectorComponent } from './tenant-category-selector/tenantCategory-search-selector.component';

import { NgxMasonryModule } from 'ngx-masonry';
import { NumbersOnlyDirective } from './numbers-only.directive';
import { CardCarouselComponent } from './card-carousel/card-carousel.component';
import { CategoryNamePipe } from 'src/app/pipes/category-name-pipe';


import { SlickCarouselModule } from 'ngx-slick-carousel';
import { MinutesToDurationFormatPipe } from 'src/app/pipes/minutes-to-duration-format.pipe';
import { SelectButtonModule, SelectButton } from 'primeng/selectbutton';
import { DynamicCardLoaderComponent } from './dynamic-card-loader/dynamic-card-loader.component';
import { CardItemDirective } from './dynamic-card-loader/card-item.directive';
import { CardListComponent } from './card-list/card-list.component';
import { EllipsisModule } from 'ngx-ellipsis';
import { DraggableListComponent } from './dragable-list-component/draggable-list.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    DragDropModule,
    InfiniteScrollModule,
    ModalModule.forRoot(),
    SweetAlert2Module,
    FileUploadModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    DropdownModule,
    MultiSelectModule,
    NgSelectModule,
    SelectButtonModule,
    ConfirmDialogModule,
    EditorModule,
    InputSwitchModule,
    TranslateModule.forChild({
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MyMissingTranslationHandler },
      parser: { provide: TranslateParser, useClass: CustomTranslateParser },
      isolate: true,
    }),
    MenubarModule,
    MenuModule,
    SidebarModule,
    PanelMenuModule,
    CarouselModule,
    ProgressBarModule,
    BlockUIModule.forRoot({
      delayStart: 500,
      delayStop: 500
    }),
    CalendarModule,
    NgxMasonryModule,
    SlickCarouselModule,
    EllipsisModule
  ],
  declarations: [
    SynapzeCxSimpleDropDownComponent,
    SynapzeCXGridComponent,
    SynapzeCXGridRowComponent,
    SynapzeCXGridColumnComponent,
    ValidationSummaryComponent,
    ValidationErrorComponent,
    ConfirmationModalComponent,
    SynapzeCxModalComponent,
    PermissionBlockComponent,
    UploadMediaComponent,
    UploadImageWithPreviewComponent,
    CustomTagsComponent,
    ContentLoaderComponent,
    SynapzeCxButtonComponent,
    TenantLocationSelectorComponent,
    TenantLocaleSelectorComponent,
    LocationNamePipe,
    JobRoleTenantRoleSelectorComponent,
    JobRoleNamePipe,
    GroupNamePipe,
    TenantRoleNamePipe,
    SecuredImageComponent,
    FileUploadImagePreviewerComponent,
    PlayVideoComponent,
    PlayPodcastComponent,
    ProfileComponent,
    SearchComponent,
    SynapzeCxRichTextComponent,
    SynapzeCxInputSwitchComponent,
    SynapzeAppMenuComponent,
    BlockUIComponent,
    SynapzeCxDisplayMediaComponent,
    PeAutoscrollFixDirective,
    SynapzeAppFooterComponent,
    SynapzeCxCalendarComponent,
    SynapzeCxFormControlComponent,
    SynapzeCxSortableComponent,
    DraggableListComponent,
    ClickOutsideDirective,
    CustomScrollDirective,
    ContentEditableInputDirective,
    CustomTagsFormControlComponent,
    TenantTagCodeSelectorComponent,
    AppComponentContainer,
    TenantCategorySelectorDropDownComponent,
    TenantCategorySelectorComponent,
    TenantCategorySearchSelectorComponent,
    NumbersOnlyDirective,
    CardCarouselComponent,
    DynamicCardLoaderComponent,
    CardItemDirective,
    CardListComponent,
    MinutesToDurationFormatPipe,
    CategoryNamePipe
  ],
  exports: [
    SynapzeCxSimpleDropDownComponent,
    SynapzeCXGridComponent,
    SynapzeCXGridRowComponent,
    SynapzeCXGridColumnComponent,
    ValidationSummaryComponent,
    ValidationErrorComponent,
    ConfirmationModalComponent,
    SynapzeCxModalComponent,
    PermissionBlockComponent,
    UploadMediaComponent,
    UploadImageWithPreviewComponent,
    CustomTagsComponent,
    ContentLoaderComponent,
    SynapzeCxButtonComponent,
    TenantLocationSelectorComponent,
    TenantLocaleSelectorComponent,
    LocationNamePipe,
    JobRoleTenantRoleSelectorComponent,
    JobRoleNamePipe,
    GroupNamePipe,
    TenantRoleNamePipe,
    SecuredImageComponent,
    FileUploadImagePreviewerComponent,
    PlayVideoComponent,
    PlayPodcastComponent,
    ProfileComponent,
    SearchComponent,
    SynapzeCxRichTextComponent,
    SynapzeCxInputSwitchComponent,
    SynapzeAppMenuComponent,
    BlockUIComponent,
    //ContenteditableModule,
    SynapzeCxDisplayMediaComponent,
    SynapzeAppFooterComponent,
    SynapzeCxCalendarComponent,
    SynapzeCxFormControlComponent,
    ContentEditableInputDirective,
    CustomTagsFormControlComponent,
    TenantTagCodeSelectorComponent,
    SynapzeCxSortableComponent,
    DraggableListComponent,
    CustomScrollDirective,
    CardModule,
    TenantCategorySelectorDropDownComponent,
    TenantCategorySelectorComponent,
    TenantCategorySearchSelectorComponent,
    NumbersOnlyDirective,
    CardCarouselComponent,
    DynamicCardLoaderComponent,
    CardItemDirective,
    CardListComponent,

    MinutesToDurationFormatPipe,
    CategoryNamePipe,
    EllipsisModule
  ],
  entryComponents: [

  ],
  providers: [
    TenantService,
    LocationsService,
    MediaService,
    ConfirmationService,
    SynapzeBlockUIService,
    SynapzeCxDialogService,
    GroupsService
  ]
})
export class SynapzeComponentsModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SynapzeComponentsModule,
      providers: [SynapzeToastrService, SynapzeCxDialogService]
    };
  }
}
